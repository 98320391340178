import styled, { css } from 'styled-components'
import 'react-dynamic-swiper/lib/styles.css'

import media from '../../common/MediaQueries'
import { transition, icon } from '../../common/Mixins'
import {
  VehicleTeaserBodyStyled,
  VehicleTeaserMediaStyled,
  VehicleTeaserContentStyled,
  VehicleTeaserActionStyled,
} from '../VehicleTeaser/styles'
import {
  VehicleExternalTeaserMediaStyled,
} from '../VehicleExternalTeaser/styles'
import ButtonLinkStyled from '../Buttons/ButtonLink/styles'

export const types = {
  one: 'one',
  two: 'two',
}

const VehicleTeasersSliderNavStyled = styled.button`
  width: ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSizePhone};
  height: ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSizePhone};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50px;
  position: absolute;
  cursor: pointer;
  top: calc(-32px 
    - ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSizePhone}
  );
  ${transition}

  svg {
    ${icon}
    font-size: 14px;
  }
  
  &.swiper-button-disabled {
    opacity: .2;
  }

  @media (${media.phone}) {
    width: ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSize};
    height: ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSize};

    svg {
      font-size: 16px;
    }
  }
`

const VehicleTeasersSliderNavPrevStyled = styled(VehicleTeasersSliderNavStyled)`
  left: 50%;
  transform: translateX(-100%) translateX(-10px);
`

const VehicleTeasersSliderNavNextStyled = styled(VehicleTeasersSliderNavStyled)`
  right: 50%;
  transform: translateX(100%) translateX(10px);
`

const VehicleTeasersSliderStyled = styled.div`
    position: relative;
    margin-bottom: ${({ theme }) => theme.gutter.gutter40};

  .swiper-container {
    overflow: visible;
    width: 100%;
    text-align: center;
  }

  .swiper-wrapper {
    align-content: stretch;
    align-items: stretch;
  }

  .swiper-slide {
    display: flex;
    height: auto;
    ${transition({ property: 'opacity' })}
  }

  /* One */
  ${({ type }) => type === types.one && css`
    padding-top: calc(32px 
      + ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSizePhone});

    &::before {
      display: block;
      content: '';
      background: ${({ theme }) => theme.colors.white};
      position: absolute;
      top: 100%;
      left: -50vw;
      width: 200vw;
      height: 100%;
    }

    .swiper-slide {
      ${VehicleTeaserMediaStyled},
      ${VehicleTeaserContentStyled},
      ${VehicleTeaserActionStyled} {
        opacity: .4;
        ${transition({ property: 'opacity' })}
      }

      ${VehicleTeaserMediaStyled},
      ${VehicleTeaserActionStyled} {
        pointer-events: none;
      }

      &-active,
      &-active + .swiper-slide-next,
      &-next + .swiper-slide {
        ${VehicleTeaserMediaStyled},
        ${VehicleTeaserContentStyled},
        ${VehicleTeaserActionStyled} {
          opacity: 1;
        }

        ${VehicleTeaserMediaStyled},
        ${VehicleTeaserActionStyled} {
          pointer-events: auto;
        }
      }            

      &:first-child {
        ${VehicleTeaserBodyStyled} {
          &::before {
            left: -50vw;
            width: 50vw;
          }
        }  
      }

      &:last-child {
        ${VehicleTeaserBodyStyled} {
          &::before {
            left: auto;
            width: 50vw;
          }
        }  
      }

      ${VehicleTeaserBodyStyled} {
        position: relative;

        &::before,
        &::after {
          display: block;
          content: '';
          background: ${({ theme }) => theme.colors.white};
          position: absolute;
          top: 0;
          width: ${({ theme }) => theme.sizes.vehicleTeaserSpaceBetweenMobile};
          height: 100%;
          z-index: -1;
        }

        &::before {
          left: -${({ theme }) => theme.sizes.vehicleTeaserSpaceBetweenMobile};
        }
        &::after {
          right: -${({ theme }) => theme.sizes.vehicleTeaserSpaceBetweenMobile};
        }
      }      
    }

    ${VehicleTeasersSliderNavStyled} {
      border: 2px solid  ${({ theme }) => theme.colors.cobalt};
      color: ${({ theme }) => theme.colors.cobalt};

      &:hover {
        background: ${({ theme }) => theme.colors.cobalt};
        color: ${({ theme }) => theme.colors.white};
      }
    }

    @media (${media.phone}) {
      padding-top: calc(50px 
        + ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSize});
      
      ${VehicleTeasersSliderNavStyled} {
        top: calc(-50px
          - ${({ theme }) => theme.sizes.vehicleTeaserSliderNavSize});
      }
    }

    @media (${media.tablet}) {
      .swiper-slide {
        ${VehicleTeaserBodyStyled} {

          &::before,
          &::after {
            width: ${({ theme }) => theme.sizes.vehicleTeaserSpaceBetween};
          }

          &::before {
            left: -${({ theme }) => theme.sizes.vehicleTeaserSpaceBetween};
          }
          &::after {
            right: -${({ theme }) => theme.sizes.vehicleTeaserSpaceBetween};
          }
        } 
      }
    }
  `}

    /* Two */
  ${({ type }) => type === types.two && css`
    ${VehicleTeasersSliderNavStyled} {
      border: 2px solid  ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.white};
      z-index: 3;

      &:hover {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.indigo};
      }
    }

    .swiper-slide {
      opacity: .4;
  
      ${VehicleExternalTeaserMediaStyled},
      ${ButtonLinkStyled} {
        pointer-events: none;
      }

      &-active,
      &-active + .swiper-slide-next {
        opacity: 1;

        ${VehicleExternalTeaserMediaStyled},
        ${ButtonLinkStyled} {
          pointer-events: auto;
        }
      }

      &-active {
        ${VehicleExternalTeaserMediaStyled} {
          position: relative;

          &::before {
            display: block;
            content: '';
            background: ${({ theme }) => theme.colors.indigo};
            position: absolute;
            top: 0;
            left: -100vw;
            width: 200vw;
            height: 100%;
            z-index: -1;
          }
        }
      }
    }

    @media (${media.phone}) {
      position: static;
      margin-bottom: 0;

      .swiper-container {
        position: static;
      }

      .swiper-slide {
        &-active {
          ${VehicleExternalTeaserMediaStyled} {
            &::before {
              display: none;
            }
          }
        }
      }

      ${VehicleTeasersSliderNavStyled} {
        top: auto;
        bottom: ${({ theme }) => theme.gutter.gutter150};
        left: 0;
      }
      ${VehicleTeasersSliderNavPrevStyled} {
        transform: none;
      }
      ${VehicleTeasersSliderNavNextStyled} {
        transform: translateX(100%) 
        translateX(${({ theme }) => theme.gutter.gutter20});
      }
    }

    @media (${media.tablet}) {
      ${VehicleTeasersSliderNavStyled} {
        bottom: ${({ theme }) => theme.gutter.gutter120};
      }
    }
  `}
`

export {
  VehicleTeasersSliderStyled,
  VehicleTeasersSliderNavPrevStyled,
  VehicleTeasersSliderNavNextStyled,
}
