import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'

const PartnersStyled = styled.div`
  padding-right: ${({ theme }) => theme.gutter.gutter16};
  padding-left: ${({ theme }) => theme.gutter.gutter16};
  background-color: ${({ theme }) => theme.colors.darkNavy};

  @media (${media.phone}) {
    padding-right: ${({ theme }) => theme.gutter.gutter32};
    padding-left: ${({ theme }) => theme.gutter.gutter32};
  }
`
const PartnersBoxStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.gutter.gutter36};
  max-width: ${({ theme }) => theme.sizes.partnersBoxWidth};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding: ${({ theme }) => theme.gutter.gutter36} 0;

  @media (${media.tablet}) {
    grid-template-columns: ${({ theme }) => theme.sizes.partnersLabelCol} 1fr;
    grid-gap: ${({ theme }) => theme.gutter.gutter24};

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: ${({ theme }) => theme.sizes.partnersLabelCol} 1fr;
  }
`

const PartnersLabelStyled = styled.div`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => rgba(theme.colors.white, 0.5)};

  @media (${media.tablet}) {
    text-align: left;

    /* IE */
    -ms-grid-column: 1;
    -ms-grid-row-align: center;
  }
`

const PartnersListStyled = styled.ul`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media (${media.tablet}) {
    /* IE */
    -ms-grid-column: 2;
  }
`

const PartnersItemStyled = styled.li`
  display: flex;
  align-items: center;
  align-content: center;
  flex-shrink: 0;
  padding: 0 0 ${({ theme }) => theme.gutter.gutter20};

  &:not(:last-child) {
    width: ${({ theme }) => theme.sizes.partnerSectionLogoWidth};
    margin-right: ${({ theme }) => theme.gutter.gutter40};
  }

  @media (${media.tablet}) {
    padding: ${({ theme }) => theme.gutter.gutter20} 0;
  }
`

const PartnerLogoStyled = styled.img`
  max-width: 100%;
  margin: 0 auto;

  max-height: ${({ theme }) => theme.sizes.partnerSectionLogoMaxHeight};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: ${({ theme }) => theme.sizes.partnerSectionLogoWidth};
  }

  /* TODO */
  /* @media (${media.tablet}) {
    margin-left: 0;
  } */
`

export {
  PartnersStyled,
  PartnersBoxStyled,
  PartnersLabelStyled,
  PartnerLogoStyled,
  PartnersListStyled,
  PartnersItemStyled,
}
