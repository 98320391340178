import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { decorGradient, transition } from '../../common/Mixins'

const VehicleExternalTeaserOverlayStyled = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (${media.tablet}) {
    &::before {
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => rgba(theme.colors.black, 0.1)};
      opacity: 0;
      ${transition}
    }
  }
`

const VehicleExternalTeaserMediaStyled = styled.a`
  display: block;
  position: relative;
  padding-top: ${({ theme }) => theme.ratio.ratio60};

  @media (${media.tablet}) {
    padding-top: ${({ theme }) => theme.ratio.ratio53};

    &:hover {
      ${VehicleExternalTeaserOverlayStyled} {
        &::before {
          opacity: 1;
        }
      }
    }
  }
`

const VehicleExternalTeaserImgStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';  
`

const VehicleExternalTeaserBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`

const VehicleExternalTeaserContentStyled = styled.div`
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter24}
    ${gutter.gutter16}
  `};
  text-align: center;
  flex-grow: 1;

  span {
    margin-left: .3em;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  @media (${media.phone}) {
    padding-top: ${({ theme }) => theme.gutter.gutter32};
    padding-bottom: ${({ theme }) => theme.gutter.gutter40};
  }
`

const VehicleExternalTeaserHeaderStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter8};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`

const VehicleExternalTeaserDescriptionStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter18};
  color: ${({ theme }) => theme.colors.gray2};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing10};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const VehicleExternalTeaserStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  
  ${decorGradient}
`

export {
  VehicleExternalTeaserStyled,
  VehicleExternalTeaserMediaStyled,
  VehicleExternalTeaserImgStyled,
  VehicleExternalTeaserBodyStyled,
  VehicleExternalTeaserContentStyled,
  VehicleExternalTeaserOverlayStyled,
  VehicleExternalTeaserHeaderStyled,
  VehicleExternalTeaserDescriptionStyled,
}
