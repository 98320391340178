import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { decorGradient, transition } from '../../common/Mixins'

const VehicleTeaserOverlayStyled = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (${media.tablet}) {
    &::before {
      z-index: 1;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => rgba(theme.colors.black, 0.1)};
      opacity: 0;
      ${transition}
    }
  }  
`

const VehicleTeaserMediaStyled = styled.button`
  display: block;
  position: relative;
  padding-top: ${({ theme }) => theme.ratio.ratio53};
  cursor: pointer;

  @media (${media.tablet}) {

    &:hover {
      ${VehicleTeaserOverlayStyled} {
        &::before {
          opacity: 1;
        }
      }
    }
  }  
`

const VehicleTeaserImgStyled = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';  
`

const VehicleTeaserBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`

const VehicleTeaserContentStyled = styled.div`
  padding: ${({ theme }) => theme.gutter.gutter16};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
  flex-grow: 1;

  span {
    margin-left: .3em;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  @media (${media.phone}) {
    padding-top: ${({ theme }) => theme.gutter.gutter20};
    padding-bottom: ${({ theme }) => theme.gutter.gutter20};
  }

  @media (${media.tablet}) {
    padding-top: ${({ theme }) => theme.gutter.gutter32};
    padding-bottom: ${({ theme }) => theme.gutter.gutter32};
  }
`

const VehicleTeaserActionStyled = styled.div`
  display: flex;
`

const VehicleTeaserStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.blueGray};
  width: 100%;

  ${decorGradient}
`

export {
  VehicleTeaserStyled,
  VehicleTeaserMediaStyled,
  VehicleTeaserImgStyled,
  VehicleTeaserBodyStyled,
  VehicleTeaserContentStyled,
  VehicleTeaserActionStyled,
  VehicleTeaserOverlayStyled,
}
