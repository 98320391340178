import React from 'react'

import {
  MediaBoxStyled,
  MediaBoxImageStyled,
  MediaBoxImageUpStyled,
  MediaBoxDescUpStyled,
  MediaBoxImageDownStyled,
  MediaBoxDescDownStyled,
} from './styles'
import ContentBox from '../ContentBox'
import { imagePropType } from '../../constants/proptypes'
import { Typo4 } from '../Typography'

const MediaBox = ({ carOne, carTwo }) => (
  <MediaBoxStyled>
    <MediaBoxImageUpStyled>
      <MediaBoxImageStyled
        fluid={carOne}
        objectFit="cover"
        objectPosition="60% 50%"
        alt="Picture"
      />
    </MediaBoxImageUpStyled>
    <MediaBoxDescUpStyled>
      <ContentBox
        header={
          <>
            <span>The world's leading</span> Enterprise Automotive imaging
            solution
          </>
        }
      >
        <Typo4>
          Our customers rely on AutoLoadIT systems to generate images for tens
          of thousands of vehicles worldwide every month. Our integrated
          hardware &amp; software solutions deliver the best possible quality
          images in minutes. It&apos;s automated vehicle photography on an
          industrial scale.
        </Typo4>
      </ContentBox>
    </MediaBoxDescUpStyled>
    <MediaBoxDescDownStyled>
      <ContentBox
        header={
          <>
            Our Camera Solution can help <span>save you valuable time</span>
          </>
        }
      >
        <Typo4>
          Our software automatically sources the vehicle data and resamples the
          photographs at source, so labelled images will upload at any broadband
          speed without compromise.
        </Typo4>
      </ContentBox>
    </MediaBoxDescDownStyled>
    <MediaBoxImageDownStyled>
      <MediaBoxImageStyled fluid={carTwo} objectFit="cover" alt="Picture" />
    </MediaBoxImageDownStyled>
  </MediaBoxStyled>
)

MediaBox.propTypes = {
  carOne: imagePropType.isRequired,
  carTwo: imagePropType.isRequired,
}

export default MediaBox
