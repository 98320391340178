import React from 'react'
import PropTypes from 'prop-types'

import {
  CommonSectionStyled,
  CommonSectionInnerStyled,
} from './styles'


const CommonSection = ({
  children,
}) => (
  <CommonSectionStyled>
    <CommonSectionInnerStyled>
      {children}
    </CommonSectionInnerStyled>
  </CommonSectionStyled>
)

CommonSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CommonSection
