import styled from 'styled-components'

import media from '../../common/MediaQueries'

const BenefitsListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;

  @media (${media.phone}) {
    flex: 1 1 0;
    justify-content: space-between;
    margin-top: -${({ theme }) => theme.gutter.gutter48};
  }

  @media (${media.tablet}) {
    margin-top: -${({ theme }) => theme.gutter.gutter76};
  }
`

const BenefitsListItemStyled = styled.li`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter32};
  }

  @media (${media.phone}) {
    margin-top: ${({ theme }) => theme.gutter.gutter48};
    width: calc(50% - ${({ theme }) => theme.gutter.gutter20});

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media (${media.tablet}) {
    margin-top: ${({ theme }) => theme.gutter.gutter76};
  }
`

const BenefitsListBoxStyled = styled.div``

const BenefitsListHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};

  height: 100%;
  min-height: ${({ theme }) => theme.sizes.benefitsHeaderHeight};

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter28};
  }
`

const BenefitsListIconStyled = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.sizes.benefitsIconWidth};
  color: ${({ theme }) => theme.colors.cobalt};

  svg {
    display: flex;
    flex-shrink: 0;
    fill: currentColor;
  }
`

const BenefitsListTitleStyled = styled.div`
  margin-left: ${({ theme }) => theme.gutter.gutter16};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (${media.phone}) {
    max-width: 60%;
  }

  @media (${media.tablet}) {
    max-width: none;
  }
`

const BenefitsListContentStyled = styled.div``

export {
  BenefitsListStyled,
  BenefitsListItemStyled,
  BenefitsListBoxStyled,
  BenefitsListHeaderStyled,
  BenefitsListIconStyled,
  BenefitsListTitleStyled,
  BenefitsListContentStyled,
}
