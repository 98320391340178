import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import PopupModal from '../PopupModal'
import VehicleSection from '../VehicleSection'
import VehicleShowcase from '../../components/VehicleShowcase'

import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'

const VehicleTeaserWrapper = ({
  teaserVehicles,
  initialTeaserPopupVehicle,
}) => {
  const [
    selectedVehicle,
    setSelectedVehicle,
  ] = useState(initialTeaserPopupVehicle)

  const closeModal = useCallback(() => {
    setSelectedVehicle(null)
  }, [])

  const openModal = useCallback((event, vehicle) => {
    event.preventDefault()
    setSelectedVehicle(vehicle)
  }, [])

  const {
    exteriorImages,
    poster,
    interiorImages,
    assets,
    popupUrl,
  } = selectedVehicle || {}

  return (
    <>
      <VehicleSection
        teaserVehicles={teaserVehicles}
        onSlideClick={openModal}
      />
      <PopupModal
        closeTimeoutMS={370}
        isOpen={!!selectedVehicle}
        closeModal={closeModal}
        popupUrl={popupUrl}
        logo={(
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.mixedLight}
          />
        )}
      >
        <VehicleShowcase
          popupMode
          assets360={exteriorImages}
          poster={poster}
          interiorImage={interiorImages}
          sliderImages={assets}
        />
      </PopupModal>
    </>
  )
}

VehicleTeaserWrapper.defaultProps = {
  initialTeaserPopupVehicle: null,
}

VehicleTeaserWrapper.propTypes = {
  teaserVehicles: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    poster: PropTypes.string,
    heading: PropTypes.string,
    assets: PropTypes.array,
    exteriorImages: PropTypes.array,
    interiorImages: PropTypes.string,
    popupUrl: PropTypes.string,
  })).isRequired,
  initialTeaserPopupVehicle: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    poster: PropTypes.string,
    heading: PropTypes.string,
    assets: PropTypes.array,
    exteriorImages: PropTypes.array,
    interiorImages: PropTypes.string,
  }),
}


export default VehicleTeaserWrapper
