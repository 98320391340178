import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  HeroStyled,
  HeroImgWrapperStyled,
  HeroImgStyled,
  HeroGradientStyled,
  HeroBodyStyled,
  HeroHeaderStyled,
  HeroDescStyled,
  HeroActionsStyled,
  HeroContactDetailsStyled,
} from './styles'
import { Heading1, Heading5 } from '../Typography'
import { InnerStyled } from '../../common/common'
import ButtonLink from '../Buttons/ButtonLink'
import { KINDS } from '../../constants/kinds'
import { fluidPropType } from '../../constants/proptypes'
import ContactDetails from '../ContactDetails'

const Hero = ({
  heros,
  popupUrl,
  onClick,
}) => (
  <HeroStyled>
    <HeroImgWrapperStyled>
      <HeroImgStyled
        fluid={heros}
        objectFit="cover"
        objectPosition="50% 70%"
      />
      <HeroGradientStyled />
    </HeroImgWrapperStyled>
    <InnerStyled>
      <HeroBodyStyled>
        <HeroHeaderStyled>
          <Heading1 as="h1">
            Stunning Images <span>Power More Sales</span>
          </Heading1>
        </HeroHeaderStyled>
        <HeroDescStyled>
          <Heading5 as="h2">
            The world&apos;s leading Automotive imaging solution
          </Heading5>
        </HeroDescStyled>
        <HeroActionsStyled>
          <ButtonLink
            href={popupUrl}
            kind={KINDS.outlineLight}
            onClick={onClick}
            aria-label="See it in action"
          >
            See it in action
          </ButtonLink>
        </HeroActionsStyled>
        <HeroContactDetailsStyled>
          <ContactDetails />
        </HeroContactDetailsStyled>
      </HeroBodyStyled>
    </InnerStyled>
  </HeroStyled>
)

Hero.defaultProps = {
  onClick: _noop,
  popupUrl: '',
}

Hero.propTypes = {
  onClick: PropTypes.func,
  heros: PropTypes.arrayOf(fluidPropType).isRequired,
  popupUrl: PropTypes.string,
}

export default Hero
