import styled from 'styled-components'

import media from '../../common/MediaQueries'

const HeadingBoxStyled = styled.div`
  text-align: center;

  @media (${media.phone}) {
    text-align: left;
  }
`

const HeadingBoxLabelStyled = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.cobalt};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};
`

const HeadingBoxTextStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter32};
  }

  @media (${media.tablet}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter48};
  }
`

const HeadingBoxActionStyled = styled.div`
`

export {
  HeadingBoxStyled,
  HeadingBoxLabelStyled,
  HeadingBoxTextStyled,
  HeadingBoxActionStyled,
}
