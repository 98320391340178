import React from 'react'
import PropTypes from 'prop-types'

import {
  PartnerSectionStyled,
  PartnerSectionInnerStyled,
  PartnerSectionBodyStyled,
  PartnerSectionInfoStyled,
  PartnerSectionHeaderStyled,
  PartnerSectionLabelStyled,
  PartnerSectionLogoStyled,
  PartnerSectionDescStyled,
  PartnerSectionInfoInnerStyled,
  PartnerSectionSliderStyled,
} from './styles'
import { SimpleLinkStyled } from '../../common/common'
import { types } from '../../components/VehicleTeasersSlider/styles'
import VehicleTeasersSlider from '../../components/VehicleTeasersSlider'
import CitygateLogo from '../../assets/logos/citygate.svg'
import { Heading3, Typo8 } from '../../components/Typography'
import VehicleExternalTeaser from '../../components/VehicleExternalTeaser'

const sliderOptions = {
  slidesPerView: 2,
  spaceBetween: 30,
  breakpoints: {
    767: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    1049: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
  },
}

const PartnerSection = ({
  teaserVehicles: slidesArray,
}) => (
  <PartnerSectionStyled>
    <PartnerSectionInnerStyled>
      <PartnerSectionBodyStyled>
        <PartnerSectionInfoStyled>
          <PartnerSectionInfoInnerStyled>
            <PartnerSectionHeaderStyled>
              <PartnerSectionLabelStyled>
                <Typo8>Autoloadit partner</Typo8>
              </PartnerSectionLabelStyled>
              <SimpleLinkStyled
                href="https://citygate.co.uk"
                target="_blank"
                rel="noopener"
              >
                <PartnerSectionLogoStyled
                  src={CitygateLogo}
                  alt="Citygate"
                />
              </SimpleLinkStyled>
            </PartnerSectionHeaderStyled>
            <PartnerSectionDescStyled>
              <Heading3 as="p">
                See for yourself, here are some of our clients live vehicles.
              </Heading3>
            </PartnerSectionDescStyled>
          </PartnerSectionInfoInnerStyled>
        </PartnerSectionInfoStyled>
        <PartnerSectionSliderStyled>
          <VehicleTeasersSlider
            type={types.two}
            slidesArray={slidesArray}
            sliderOptions={sliderOptions}
            slideComponent={VehicleExternalTeaser}
          />
        </PartnerSectionSliderStyled>
      </PartnerSectionBodyStyled>
    </PartnerSectionInnerStyled>
  </PartnerSectionStyled>
)

PartnerSection.propTypes = {
  teaserVehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    price: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
}

export default PartnerSection
