import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '42',
  height: '36',
  viewBox: '0 0 42 36',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M23.9 35.857a17.845 17.845 0 01-5.862-.986 18.1 18.1 0 01-5.055-2.719l2.829-2.868A13.946 13.946 0 109.96 17.929h5.976L7.969 25.9 0 17.929h5.976A17.938 17.938 0 1123.9 35.857zm0-13.945a3.984 3.984 0 113.984-3.983 3.988 3.988 0 01-3.984 3.983z"
      key="key-turn"
    />
  ),
  displayName: 'Turn',
})
