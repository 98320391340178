import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import media from '../../common/MediaQueries'

const MediaBoxStyled = styled.div`
  display: grid;
  grid-template-columns: 8px 1fr 8px;
  grid-template-rows: 180px 44px auto 180px 44px auto;

  @media (${media.phone}) {
    grid-template-columns: 44px 1fr 44px;
    grid-template-rows: 300px 64px auto 300px 64px auto;
  }

  @media (${media.tablet}) {
    grid-template-columns: 1fr .75fr;
    grid-template-rows: auto 140px auto;

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: 1fr .75fr;
    -ms-grid-rows: auto 140px auto;
  }
`

const MediaBoxImageUpStyled = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 3;

  @media (${media.tablet}) {
    grid-column: 1 / 2;

    /* IE */
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
  }
`

const MediaBoxDescUpStyled = styled.div`
  position: relative;
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter16} 
    ${gutter.gutter16}
    ${gutter.gutter32}
  `};
  background: ${({ theme }) => theme.colors.white};

  @media (${media.phone}) {
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter64} 
      ${gutter.gutter76}
    `};
  }

  @media (${media.tablet}) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding: ${({ theme: { gutter } }) => `
      0
      0
      ${gutter.gutter70}
    `};

    &::before {
      z-index: 1;
      display: block;
      content: '';
      background: ${({ theme }) => theme.colors.white};
      position: absolute;
      top: 0;
      left: -${({ theme }) => theme.sizes.contentBoxDescIndentLeft};
      width: ${({ theme }) => theme.sizes.contentBoxDescIndentLeft};
      height: 100%;
    }

    /* IE */
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
`

const MediaBoxDescDownStyled = styled.div`
  z-index: 1;
  grid-column: 2 / 3;
  grid-row: 5 / 7;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter16} 
    ${gutter.gutter16}
    ${gutter.gutter32}
  `};
  background: ${({ theme }) => theme.colors.white};
  
  @media (${media.phone}) {
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter64} 
      ${gutter.gutter76}
    `};
  }

  @media (${media.tablet}) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter96}
      0
      0
      ${gutter.gutter96}
    `};
    background: transparent;

    /* IE */
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
`

const MediaBoxImageDownStyled = styled.div`
  grid-column: 1 / 4;
  grid-row: 4 / 6;
  
  @media (${media.tablet}) {
    grid-column: 2 / 3;
    grid-row: 2 / 4;

    /* IE */
    -ms-grid-column: 2;
    -ms-grid-row: 2;    
    -ms-grid-row-span: 2;
  }
`

const MediaBoxImageStyled = styled(Img)`
  width: 100%;
  object-fit: cover;
  height: 100%;
`

export {
  MediaBoxStyled,
  MediaBoxImageStyled,
  MediaBoxImageUpStyled,
  MediaBoxDescUpStyled,
  MediaBoxImageDownStyled,
  MediaBoxDescDownStyled,
}
