import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '38',
  height: '23',
  viewBox: '0 0 38 23',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M26.6 23l4.351-4.389-9.272-9.354-7.6 7.667L0 2.7 2.679 0l11.4 11.5 7.6-7.667 11.97 12.056L38 11.5V23z"
      key="key-stock"
    />
  ),
  displayName: 'Stock',
})
