import React from 'react'
import PropTypes from 'prop-types'

import {
  ClientQuoteStyled,
  ClientQuoteMediaStyled,
  ClientQuoteContentStyled,
  ClientQuoteImageStyled,
  ClientQuoteHeadingStyled,
  ClientQuoteLabelStyled,
  ClientQuoteLogoWrapperStyled,
  ClientQuoteLogoStyled,
} from './styles'
import { Heading3, Typo7 } from '../Typography'
import { fluidPropType } from '../../constants/proptypes'

const ClientQuote = ({
  children,
  label,
  fluid,
  logo,
  alt,
  ...rest
}) => (
  <ClientQuoteStyled>
    <ClientQuoteMediaStyled>
      <ClientQuoteImageStyled
        fluid={fluid}
        objectFit="cover"
        objectPosition="50% 0%"
        {...rest}
      />
    </ClientQuoteMediaStyled>
    <ClientQuoteContentStyled>
      <ClientQuoteHeadingStyled>
        <Heading3>{children}</Heading3>
      </ClientQuoteHeadingStyled>
      <ClientQuoteLabelStyled>
        <Typo7>{label}</Typo7>
      </ClientQuoteLabelStyled>
      <ClientQuoteLogoWrapperStyled>
        <ClientQuoteLogoStyled src={logo} alt={alt} />
      </ClientQuoteLogoWrapperStyled>
    </ClientQuoteContentStyled>
  </ClientQuoteStyled>
)

ClientQuote.propTypes = {
  fluid: fluidPropType.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
}

export default ClientQuote
