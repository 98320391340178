import React from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'
import _noop from 'lodash/noop'

import {
  VehicleSectionStyled,
  VehicleSectionHeaderStyled,
  VehicleSectionInnerStyled,
} from './styles'
import { types } from '../../components/VehicleTeasersSlider/styles'
import VehicleTeasersSlider from '../../components/VehicleTeasersSlider'
import { Heading2 } from '../../components/Typography'
import VehicleTeaser from '../../components/VehicleTeaser'
import MENU from '../../constants/menu'

const sliderOptions = {
  slidesPerView: 3,
  spaceBetween: 30,
  breakpoints: {
    767: {
      slidesPerView: 1,
    },
    1049: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
  },
}

const VehicleSection = ({
  teaserVehicles: slidesArray,
  onSlideClick,
}) => (
  <Element id={`${MENU.DEMO.to}`} name={MENU.DEMO.to}>
    <VehicleSectionStyled>
      <VehicleSectionInnerStyled>
        <VehicleSectionHeaderStyled>
          <Heading2 as="h3">
            <span>See it for yourself</span>, a picture paints a thousand words
          </Heading2>
        </VehicleSectionHeaderStyled>
        <VehicleTeasersSlider
          type={types.one}
          slidesArray={slidesArray}
          sliderOptions={sliderOptions}
          slideComponent={VehicleTeaser}
          onSlideClick={onSlideClick}
        />
      </VehicleSectionInnerStyled>
    </VehicleSectionStyled>
  </Element>
)

VehicleSection.defaultProps = {
  onSlideClick: _noop,
}

VehicleSection.propTypes = {
  onSlideClick: PropTypes.func,
  teaserVehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
  })).isRequired,
}

export default VehicleSection
