import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { transition, icon } from '../../common/Mixins'

const AccordionIconStyled = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.gutter.gutter16};
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.cobalt};
  transform: translateY(-50%);
  ${transition}

  svg {
    ${icon}
    width: 1.6em;
    font-size: 11px;
  }

  @media (${media.phone}) {
    right: ${({ theme }) => theme.gutter.gutter28};
  }

  @media (${media.tablet}) {
    font-size: 13px;
  }
`

const AccordionContentStyled = styled.div`
  padding-right: ${({ theme }) => theme.gutter.gutter24};
  padding-left: ${({ theme }) => theme.gutter.gutter24};
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  ${transition}

  @media (${media.phone}) {
    padding-right: ${({ theme }) => theme.gutter.gutter56};
    padding-left: ${({ theme }) => theme.gutter.gutter28};
  }
`

const AccordionStyled = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 2px solid ${({ theme }) => rgba(theme.colors.cobalt, 0.2)};
  background: ${({ theme }) => rgba(theme.colors.white, 0.3)};
  transition: border-color 370ms ease, background 370ms ease;

  ${props => props.isActive && css`
    border: 2px solid ${({ theme }) => theme.colors.cobalt};
    background: ${({ theme }) => theme.colors.white};

    ${AccordionContentStyled} {
      opacity: 1;
      max-height: 100vh;
    }
  `}

  @media (${media.phone}) {
    ${props => props.isActive && css`
      ${AccordionContentStyled} {
        padding-top: ${({ theme }) => theme.gutter.gutter8};
      }
    `}
  }
`

const AccordionHeaderStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const AccordionButtonStyled = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter16} 
    ${gutter.gutter52}
    ${gutter.gutter16} 
    ${gutter.gutter24}    
  `};  
  cursor: pointer;
  ${transition}

  ${props => props.isActive && css`
    ${AccordionIconStyled} {
      transform: translateY(-50%) rotate(180deg);
    }
  `}

  @media (${media.phone}) {
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter20} 
      ${gutter.gutter64}
      ${gutter.gutter20} 
      ${gutter.gutter28}    
    `};      
  }

  @media (${media.tablet}) {
    &:hover {
      color: ${({ theme }) => theme.colors.cobalt};
    }
  }
`

const AccordionTextStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter18};
`

export {
  AccordionStyled,
  AccordionHeaderStyled,
  AccordionButtonStyled,
  AccordionIconStyled,
  AccordionContentStyled,
  AccordionTextStyled,
}
