import styled from 'styled-components'

import media from '../../common/MediaQueries'

const ClientQuotesSectionStyled = styled.div`
  margin-top: ${({ theme }) => theme.gutter.gutter32};

  @media (${media.phone}) {
    margin-top: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    margin-top: ${({ theme }) => theme.gutter.gutter150};
  }
`

export {
  // eslint-disable-next-line import/prefer-default-export
  ClientQuotesSectionStyled,
}
