import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '27',
  height: '48',
  viewBox: '0 0 27 48',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M1.5 34.2c1.8-1.2 3.2-2.6 4.3-4.1s1.6-3.1 1.6-4.9c0-.5-.1-1.1-.3-1.8-.1-.4-.3-1-.6-1.7H1.3v-2.4H5c0 .1-.3-.4-.8-1.5s-.9-1.8-1-2.3c-.2-.5-.3-1-.4-1.6s-.2-1.2-.2-1.9c0-2.4 1-4.6 3-6.6s4.8-2.9 8.5-2.9c3.5 0 6.1 1 7.9 2.9s2.7 4.6 2.8 8.1h-4.3c0-2.4-.6-4.2-1.7-5.4s-2.8-1.8-5-1.8-3.8.6-5 1.7-1.7 2.5-1.7 4.2c0 .8.2 1.6.5 2.5s1.1 2.4 2.2 4.6h7.7v2.4h-6.6c.2.7.3 1.3.3 1.7s.1.8.1 1.3c0 1.6-.5 3.1-1.4 4.6s-2.4 3-4.4 4.5c1.1-.5 2.1-1 3.2-1.3s2.1-.5 3.1-.5c1.1 0 2.6.3 4.5.8s3.1.8 3.6.8c.7 0 1.4-.1 2-.4.4-.2.9-.5 1.7-1.1l1.9 3.2c-.9.7-1.7 1.2-2.3 1.6-1.1.5-2.4.8-3.6.8-.9 0-2.5-.4-4.7-1.1s-4-1.1-5.2-1.1c-1.2 0-2.3.2-3.4.6-.6.2-1.4.7-2.4 1.3l-2.4-3.2z"
      key="key-pound"
    />
  ),
  displayName: 'Pound',
})
