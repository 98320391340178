import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'

import { KINDS } from '../../../constants/kinds'
import { SIZES } from '../../../constants/sizes'
import {
  buttonBase,
  outlineDark,
  medium,
} from '../styles'

const ButtonLinkScrollStyled = styled(Link)`
  ${buttonBase}

  /* Outlined dark */
  ${({ kind }) => kind === KINDS.outlineDark && css`
    ${outlineDark}
  `}

  /* Medium */
  ${({ size }) => size === SIZES.medium && css`
    ${medium}
  `}
`

export default ButtonLinkScrollStyled
