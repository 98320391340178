import styled, { css } from 'styled-components'

import {
  buttonBase,
  rounded,
  withIcon,
  outlineLight,
  medium,
  big,
} from '../styles'
import { SIZES } from '../../../constants/sizes'
import { KINDS } from '../../../constants/kinds'

const ButtonLinkStyled = styled.a`
  ${buttonBase}

  /* Rounded */
  ${({ kind }) => kind === KINDS.rounded && css`
    ${rounded}
  `}

  /* WithIcon*/
  ${({ kind }) => kind === KINDS.withIcon && css`
    ${withIcon}
  `} 

  /* Outline Light */
  ${({ kind }) => kind === KINDS.outlineLight && css`
    ${outlineLight}
  `}

  /* Medium */
  ${({ size }) => size === SIZES.medium && css`
    ${medium}
  `}

  /* Big */
  ${({ size }) => size === SIZES.big && css`
    ${big}
  `}
`

export default ButtonLinkStyled
