import React from 'react'
import PropTypes from 'prop-types'

import ButtonLinkScrollStyled from './styles'
import { Typo6 } from '../../Typography'
import { SIZES } from '../../../constants/sizes'
import { KINDS } from '../../../constants/kinds'

const ButtonLinkScroll = ({
  children,
  ...rest
}) => (
  <ButtonLinkScrollStyled
    {...rest}
  >
    <Typo6>{children}</Typo6>
  </ButtonLinkScrollStyled>
)

ButtonLinkScroll.defaultProps = {
  kind: 'outlineDark',
  size: 'medium',
}

ButtonLinkScroll.propTypes = {
  kind: PropTypes.oneOf(Object.keys(KINDS)),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ButtonLinkScroll
