import React, { useState, useCallback } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import PropTypes from 'prop-types'

import {
  AccordionStyled,
  AccordionHeaderStyled,
  AccordionButtonStyled,
  AccordionIconStyled,
  AccordionContentStyled,
  AccordionTextStyled,
} from './styles'
import { Typo2, Typo4 } from '../Typography'
import ArrowDownIcon from '../Icons/ArrowDown'

const Accordion = ({
  title,
  children,
}) => {
  const [isActive, setIsActive] = useState(false)

  const toggleAccordion = useCallback(e => {
    setIsActive(prevIsActive => !prevIsActive)

    const currentOffset = e.currentTarget.getBoundingClientRect().top
    const header = document.querySelector('header')

    if (!header) return

    const scrollDistance = currentOffset - header.offsetHeight

    scroll.scrollMore(scrollDistance)
  }, [])

  return (
    <AccordionStyled
      isActive={isActive}
    >
      <AccordionHeaderStyled>
        <AccordionButtonStyled
          isActive={isActive}
          onClick={toggleAccordion}
        >
          <Typo2>
            {title}
          </Typo2>
          <AccordionIconStyled>
            <ArrowDownIcon />
          </AccordionIconStyled>
        </AccordionButtonStyled>
      </AccordionHeaderStyled>
      <AccordionContentStyled>
        <AccordionTextStyled>
          <Typo4>{children}</Typo4>
        </AccordionTextStyled>
      </AccordionContentStyled>
    </AccordionStyled>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Accordion
