import React, { useState, useCallback } from 'react'
import { Element } from 'react-scroll'

import PopupModal from '../PopupModal'
import ContactSection from '../ContactSection'
import CommonSection from '../CommonSection'
import ContactForm from '../../components/ContactForm'
import Software from '../../components/Software'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import MENU from '../../constants/menu'
import { fluidPropType } from '../../constants/proptypes'

const SoftwareWrapper = ({ car }) => {
  const [isOpened, setIsOpened] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  return (
    <>
      <Element id={`${MENU.SOLUTIONS.to}`} name={MENU.SOLUTIONS.to}>
        <CommonSection>
          <Software onClick={openModal} />
        </CommonSection>
      </Element>
      <PopupModal
        closeTimeoutMS={370}
        isOpen={isOpened}
        closeModal={closeModal}
        logo={
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.mixedLight}
          />
        }
        isAlt
      >
        <ContactSection car={car}>
          <ContactForm />
        </ContactSection>
      </PopupModal>
    </>
  )
}

SoftwareWrapper.propTypes = {
  car: fluidPropType.isRequired,
}

export default SoftwareWrapper
