import React from 'react'
import PropTypes from 'prop-types'

import {
  ColsSectionWrapperStyled,
  ColsSectionInnerStyled,
  ColsSectionContentStyled,
  ColsSectionAsideStyled,
  ColsSectionMainStyled,
  ColsSectionDecorImgWrapperStyled,
  ColsSectionDecorImgStyled,
} from './styles'
import { fixedPropType } from '../../constants/proptypes'

const ColsSection = ({
  bg,
  aside,
  children,
  decor,
}) => (
  <ColsSectionWrapperStyled
    bg={bg}
  >
    <ColsSectionInnerStyled>
      <ColsSectionContentStyled>
        <ColsSectionAsideStyled>
          {aside}
        </ColsSectionAsideStyled>
        <ColsSectionMainStyled>
          {children}
        </ColsSectionMainStyled>
      </ColsSectionContentStyled>
    </ColsSectionInnerStyled>
    {decor && (
      <ColsSectionDecorImgWrapperStyled>
        <ColsSectionDecorImgStyled fixed={decor} alt="AutoloadIT" />
      </ColsSectionDecorImgWrapperStyled>
    )}
  </ColsSectionWrapperStyled>
)

ColsSection.defaultProps = {
  bg: '',
  decor: undefined,
}

ColsSection.propTypes = {
  bg: PropTypes.string,
  aside: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  decor: fixedPropType,
}

export default ColsSection
