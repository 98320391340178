import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '18',
  height: '18',
  viewBox: '0 0 18 18',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M18 .8v4.1c0 .4-.4.8-.8.8s-.8-.4-.8-.8V2.8l-4.7 4.7c-.2.2-.4.2-.6.2-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.2l4.7-4.7h-2.1c-.4 0-.8-.4-.8-.8s.3-.8.8-.8h4.1c.2 0 .4.1.6.2.1.2.2.4.2.6zm-.8 11.5c-.4 0-.8.4-.8.8v2.1l-4.7-4.6c-.3-.3-.9-.3-1.2 0-.3.3-.3.8 0 1.2l4.6 4.6H13c-.4 0-.8.4-.8.8 0 .5.4.8.8.8h4.1c.2 0 .5-.1.6-.2.2-.2.3-.4.3-.6v-4.1c0-.5-.4-.8-.8-.8zM6.3 10.5l-4.7 4.7v-2.1c0-.4-.4-.8-.8-.8s-.8.4-.8.8v4.1c0 .2.1.4.2.6.2.1.4.2.6.2h4.1c.4 0 .8-.4.8-.8s-.4-.8-.8-.8H2.8l4.7-4.7c.3-.3.3-.8 0-1.2-.3-.3-.9-.3-1.2 0zM2.8 1.7h2.1c.4 0 .8-.4.8-.8S5.4.1 4.9.1H.8C.6.1.4.2.2.3.1.5 0 .7 0 .9V5c0 .4.4.8.8.8s.8-.4.8-.8V2.9l4.7 4.7c.2.2.4.2.6.2s.4-.1.6-.2c.3-.3.3-.8 0-1.2L2.8 1.7z"
      key="key-expand"
    />
  ),
  displayName: 'Expand',
})
