import React, { useState, useCallback } from 'react'
import { Element } from 'react-scroll'

import theme from '../../theme'
import PopupModal from '../PopupModal'
import ContactSection from '../ContactSection'
import ColsSection from '../ColsSection'
import ContactForm from '../../components/ContactForm'
import HeadingBox from '../../components/HeadingBox'
import AccordionList from '../../components/AccordionList'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import MENU from '../../constants/menu'
import { fluidPropType, fixedPropType } from '../../constants/proptypes'

const GetInTouchWrapper = ({
  decor,
  car,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  return (
    <>
      <Element id={`${MENU.FAQS.to}`} name={MENU.FAQS.to}>
        <ColsSection
          decor={decor}
          bg={theme.colors.blueGrayLight}
          aside={(
            <HeadingBox
              label="Got questions?"
              btnLabel="Get in touch"
              onClick={openModal}
            >
              Go to FAQ&apos;s
            </HeadingBox>
          )}
        >
          <AccordionList openModal={openModal} />
        </ColsSection>
      </Element>
      <PopupModal
        popupUrl="/contact"
        closeTimeoutMS={370}
        isOpen={isOpened}
        closeModal={closeModal}
        logo={(
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.mixedLight}
          />
        )}
        isAlt
      >
        <ContactSection
          car={car}
        >
          <ContactForm />
        </ContactSection>
      </PopupModal>
    </>
  )
}

GetInTouchWrapper.propTypes = {
  car: fluidPropType.isRequired,
  decor: fixedPropType.isRequired,
}

export default GetInTouchWrapper
