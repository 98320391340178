import React from 'react'
import PropTypes from 'prop-types'

import {
  ContentBoxStyled,
  ContentBoxHeaderStyled,
  ContentBoxBodyStyled,
} from './styles'
import { Heading2 } from '../Typography'

const ContentBox = ({ header, children }) => (
  <ContentBoxStyled>
    <ContentBoxHeaderStyled>
      <Heading2 as="h3">{header}</Heading2>
    </ContentBoxHeaderStyled>
    <ContentBoxBodyStyled>{children}</ContentBoxBodyStyled>
  </ContentBoxStyled>
)

ContentBox.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
}

export default ContentBox
