import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import { decorHeading } from '../../common/Mixins'
import media from '../../common/MediaQueries'

const ClientQuoteStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 288px auto;
  grid-template-areas: 'media' 'content';
  text-align: center;
  width: 100%;
  
  @media (${media.phone}) {
    grid-template-rows: 500px auto;
    text-align: left;
  }

  @media (${media.tablet}) {
    grid-template-columns: calc(50% - ${({ theme }) => theme.gutter.gutter32}) 
      calc(50% + ${({ theme }) => theme.gutter.gutter32});
    grid-template-rows: auto;
    grid-template-areas: 'media content';

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: calc(50% - ${({ theme }) => theme.gutter.gutter32}) 
      calc(50% + ${({ theme }) => theme.gutter.gutter32});
  }
`

const ClientQuoteMediaStyled = styled.div`
  grid-area: media;
  display: flex;
`

const ClientQuoteImageStyled = styled(Img)`
  width: 100%;
  max-width: 100%;
  height: 100%;
`

const ClientQuoteContentStyled = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter32}  
    ${gutter.gutter24}
  `};

  @media (${media.phone}) {
    padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter128}  
    ${gutter.gutter48} 
    ${gutter.gutter64}
    ${gutter.gutter64}
  `};    
  }

  @media (${media.tablet}) {
    padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter128}  
    ${gutter.gutter40} 
    ${gutter.gutter40}
    ${gutter.gutter112}
  `};

    /* IE */
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
`

const ClientQuoteHeadingStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  max-width: ${({ theme }) => theme.sizes.clientQuoteHeadingTabletWidth};
  ${decorHeading}

  @media (${media.tablet}) {
    max-width: ${({ theme }) => theme.sizes.clientQuoteHeadingWidth};
  }
`

const ClientQuoteLabelStyled = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  flex-grow: 1;
  padding-top: ${({ theme }) => theme.gutter.gutter8};
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};

  @media (${media.tablet}) {
    padding-top: 0;
  }
`

const ClientQuoteLogoWrapperStyled = styled.div`
  display: flex;
  justify-content: center;

  @media (${media.phone}) {
    justify-content: flex-end;
  }
`

const ClientQuoteLogoStyled = styled.img`
  max-width: ${({ theme }) => theme.sizes.clientQuoteLogoWidth};
`

export {
  ClientQuoteStyled,
  ClientQuoteMediaStyled,
  ClientQuoteContentStyled,
  ClientQuoteImageStyled,
  ClientQuoteHeadingStyled,
  ClientQuoteLabelStyled,
  ClientQuoteLogoWrapperStyled,
  ClientQuoteLogoStyled,
}
