import React from 'react'
import PropTypes from 'prop-types'

import {
  ClientQuotesSectionStyled,
} from './styles'
import ClientQuotesSlider from '../../components/ClientQuotesSlider'
import CitygateLogo from '../../assets/logos/citygate-alt.svg'
import AutoTraderLogo from '../../assets/logos/autotrader.png'
import CarNextLogo from '../../assets/logos/carnext.png'
import { fluidPropType } from '../../constants/proptypes'

const items = [
  {
    alt: 'Autotrader',
    heading: `“CarShop get vastly more click-thrus on the Autotrader website
    than any other car supermarket in the UK. The quality of
    their photography is the best in the industry.”`,
    label: 'Autotrader\u2122',
    logo: AutoTraderLogo,
  },
  {
    alt: 'CarNext',
    heading: `“CarNext Portugal noticed a 30% reduction in the time it takes
    to sell a vehicle, allowing more stockturn with the same space.”`,
    label: 'CarNext\u2122 | LeasePlan\u2122',
    logo: CarNextLogo,
  },
  {
    alt: 'Citygate',
    heading: `“The Citygate Group use AutoLoadIT photography. They get almost twice
    the number of click-thrus on the Volkswagen used vehicles website compared
    with any other Volkswagen dealership in the UK.”`,
    label: 'Volkswagen\u2122 and Manheim\u2122 | Cox Automotive\u2122',
    logo: CitygateLogo,
  },
]

const ClientQuotesSection = ({
  images,
}) => {
  const slidesArray = items.map((item, index) => ({
    ...item,
    fluid: images[index].node.fluid,
  }))

  return (
    <ClientQuotesSectionStyled>
      <ClientQuotesSlider
        slidesArray={slidesArray}
      />
    </ClientQuotesSectionStyled>
  )
}

ClientQuotesSection.propTypes = {
  images: PropTypes.arrayOf(fluidPropType).isRequired,
}

export default ClientQuotesSection
