import styled from 'styled-components'

import media from '../../common/MediaQueries'

const SoftwareStyled = styled.div`
  display: grid;
  grid-template-areas: 'content' 'media';
  grid-template-columns: 1fr;

  @media (${media.tablet}) {
    grid-template-areas: 'content media';
    grid-template-columns: 55% 45%;
    grid-template-rows: minmax(665px, 1fr);

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: 55% 45%;
    -ms-grid-rows: minmax(665px, 1fr);
  }
`

const SoftwareContentStyled = styled.div`
  grid-area: content;
  position: relative;
  padding: ${({ theme: { gutter } }) => `
    ${gutter.gutter32} 
    0
    ${gutter.gutter16}
  `};
  text-align: center;

  &::before {
    position: absolute;
    top: -${({ theme }) => theme.gutter.gutter32};
    left: 0;
    right: 0;
    content: '';
    max-width: ${({ theme }) => theme.sizes.softwareBgDecorPhoneWidth};
    height: 100%;
    background: url(${(props) => props.bg}) no-repeat left;
    background-size: contain;
    margin: 0 auto;
  }

  @media (${media.phone}) {
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter32} 
      ${gutter.gutter56}
      ${gutter.gutter32}
    `};
    text-align: left;

    &::before {
      display: none;
    }
  }

  @media (${media.tablet}) {
    padding: ${({ theme: { gutter } }) => `
      ${gutter.gutter112} 
      0
      ${gutter.gutter96} 
      0
    `};

    &::before {
      display: block;
      top: 0;
      right: auto;
      margin: 0;
      transform: translateX(-50%) translateX(-50px);
      max-width: none;
      width: ${({ theme }) => theme.sizes.softwareBgDecorWidth};
      height: 100%;
    }

    /* IE */
    -ms-grid-column: 1;
  }
`

const SoftwareMediaStyled = styled.div`
  position: relative;
  grid-area: media;

  @media (${media.tablet}) {
    display: grid;
    grid-template-columns: auto 280px;
    grid-template-rows:
      ${({ theme }) => theme.gutter.gutter120} auto
      ${({ theme }) => theme.gutter.gutter96};

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: 1fr 280px;
    -ms-grid-rows: ${({ theme }) => theme.gutter.gutter120} 1fr
      ${({ theme }) => theme.gutter.gutter96};
    -ms-grid-column: 2;
  }
`

const SoftwareDecorStyled = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.indigo};

  &::before,
  &::after {
    display: block;
    content: '';
    width: 50vw;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: inherit;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  @media (${media.tablet}) {
    top: 0;
    left: auto;
    width: ${({ theme }) => theme.sizes.softwareDecorWidth};

    &::before {
      display: none;
    }
  }
`

const SoftwareActionStyled = styled.div`
  margin-top: ${({ theme }) => theme.gutter.gutter48};
`

const SoftwareImgStyled = styled.img`
  max-width: 100%;
  position: relative;
  z-index: 1;
  margin-left: -6%;

  @media (${media.phone}) and (orientation: landscape) {
    margin-left: 0;
  }

  @media (${media.tablet}) {
    max-width: none;
    position: absolute;
    top: 50%;
    right: -52%;
    transform: translateY(-50%);
  }
`

const SoftwareBottomStyled = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.gutter.gutter36};

  @media (${media.tablet}) {
    grid-row: 3;
    grid-column: 2;
    align-self: end;
    padding-left: ${({ theme }) => theme.gutter.gutter56};
    text-align: left;

    /* IE */
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-row-align: end;
  }
`

const SoftwareDescStyled = styled.div`
  opacity: 0.45;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  margin-bottom: ${({ theme }) => theme.gutter.gutter10};
`

const SoftwareLogoStyled = styled.img`
  max-height: ${({ theme }) => theme.sizes.softwareLogoHeightPhone};

  @media (${media.phone}) {
    max-height: none;
  }
`

export {
  SoftwareStyled,
  SoftwareContentStyled,
  SoftwareMediaStyled,
  SoftwareDecorStyled,
  SoftwareImgStyled,
  SoftwareActionStyled,
  SoftwareBottomStyled,
  SoftwareDescStyled,
  SoftwareLogoStyled,
}
