import React from 'react'

import {
  PartnersStyled,
  PartnersBoxStyled,
  PartnersLabelStyled,
  PartnersListStyled,
  PartnersItemStyled,
  PartnerLogoStyled,
} from './styles'
import { Typo7 } from '../Typography'

import ArsLogo from '../../assets/logos/ars.png'
import CarNextLogo from '../../assets/logos/carnext.png'
import CarStoreLogo from '../../assets/logos/carstore.png'
import CatLogo from '../../assets/logos/cat.png'
import DekraLogo from '../../assets/logos/dekra.jpg'
import EscargoLogo from '../../assets/logos/escargo.png'
import GcaLogo from '../../assets/logos/gca.jpg'
import GefcoLogo from '../../assets/logos/gefco.png'
import KoopmanLogo from '../../assets/logos/koopman.png'
import LagermaxLogo from '../../assets/logos/lagermax.svg'
import NvdLogo from '../../assets/logos/nvd.png'
import SarmedLogo from '../../assets/logos/sarmed.jpg'
import SchlosserLogo from '../../assets/logos/schlosser.png'
import SmhLogo from '../../assets/logos/smh.png'
import VertuLogo from '../../assets/logos/vertu.jpg'
import CitygateLogo from '../../assets/logos/citygate.svg'
import CarShopLogo from '../../assets/logos/carshop.png'
import SytnerGroupLogo from '../../assets/logos/sytnergroup.png'
import BcaLogo from '../../assets/logos/bca.png'
import AldAutomativepLogo from '../../assets/logos/aldautomative.png'
import withInnerHeight from '../../global/InnerHeightObserver/withInnerHeight'

const logos = [
  {
    url: AldAutomativepLogo,
    alt: 'ALD Automative',
  },
  {
    url: ArsLogo,
    alt: 'Ars',
  },
  {
    url: BcaLogo,
    alt: 'BCA',
  },
  {
    url: CarShopLogo,
    alt: 'Car Shop',
  },
  {
    url: CarNextLogo,
    alt: 'CarNext',
  },
  {
    url: CarStoreLogo,
    alt: 'CarStore',
  },
  {
    url: CitygateLogo,
    alt: 'Citygate',
  },
  {
    url: DekraLogo,
    alt: 'Dekra',
  },
  {
    url: EscargoLogo,
    alt: 'Escargo',
  },
  {
    url: GcaLogo,
    alt: 'Gca',
  },
  {
    url: GefcoLogo,
    alt: 'Gefco',
  },
  {
    url: CatLogo,
    alt: 'Cat',
  },
  {
    url: KoopmanLogo,
    alt: 'Koopman',
  },
  {
    url: LagermaxLogo,
    alt: 'Lagermax',
  },
  {
    url: NvdLogo,
    alt: 'Nvd',
  },
  {
    url: SarmedLogo,
    alt: 'Sarmed',
  },
  {
    url: SchlosserLogo,
    alt: 'Schlosser',
  },
  {
    url: SmhLogo,
    alt: 'Smh',
  },
  {
    url: SytnerGroupLogo,
    alt: 'Sytner Group',
  },
  {
    url: VertuLogo,
    alt: 'Vertu',
  },
]

const Partners = () => (
  <PartnersStyled>
    <PartnersBoxStyled>
      <PartnersLabelStyled>
        <Typo7>Our partners in over 20 countries include</Typo7>
      </PartnersLabelStyled>
      <PartnersListStyled>
        {logos.map(({ url, alt }) => (
          <PartnersItemStyled key={url}>
            <PartnerLogoStyled src={url} alt={alt} />
          </PartnersItemStyled>
        ))}
      </PartnersListStyled>
    </PartnersBoxStyled>
  </PartnersStyled>
)

export default withInnerHeight(Partners)
