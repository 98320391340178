import styled, { css } from 'styled-components'
import 'react-tabs/style/react-tabs.css'
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import ButtonNavigationStyled from '../Navigation/styles'
import { LoaderStyled } from '../AssetLoader/styles'

import {
  transition,
  navigationGradient,
  borderDecor,
} from '../../common/Mixins'
import withInnerHeight from '../../global/InnerHeightObserver/withInnerHeight'

const VehicleShowcaseLogoStyled = styled.div`
  z-index: 100;
  position: absolute;
  top: ${({ theme }) => theme.gutter.gutter20};
  left: ${({ theme }) => theme.gutter.gutter64};
`

const VehicleShowcaseNavButtonStyled = styled.button`
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing04};
  cursor: pointer;

  &::after {
    position: absolute;
    left: 0;
    bottom: -3px;
    transform: scaleX(0);
    transform-origin: bottom right;
    display: block;
    content: '';
    width: 100%;
    height: 4px;
    background: ${({ theme }) => theme.colors.arctic};
    ${transition({ property: 'transform' })}
  }

  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  p {
    white-space: nowrap;
  }
  
  @media (${media.tablet}) {
    &::after {
      background: ${({ theme }) => theme.colors.cobalt};
    }
  }
`

const VehicleShowcaseNavStyled = styled.div`
  z-index: 100;
  position: absolute;
  top: ${({ theme }) => theme.gutter.gutter10};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  @media (${media.phone}) {
    top: ${({ theme }) => theme.gutter.gutter20};
  }
`

const VehicleShowcaseTabListStyled = styled(TabList)`
  position: relative;
  display: flex;
  margin: 0;
  list-style-type: none;
  border: 2px solid ${({ theme }) => rgba(theme.colors.cobalt, 0.3)};
  color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.sizes.vehicleShowcaseNavHeightMobile};
  border-radius: 3em;
  background: ${({ theme }) => rgba(theme.colors.darkerGray, 0.4)};
  padding: 0 ${({ theme }) => theme.gutter.gutter16};
  ${navigationGradient}

  @media (${media.phone}) {
    padding: 0 ${({ theme }) => theme.gutter.gutter32};
    height: ${({ theme }) => theme.sizes.vehicleShowcaseNavHeight};
  }

  @media (${media.tablet}) {
    color: ${({ theme }) => theme.colors.darkNavy};
    background: ${({ theme }) => rgba(theme.colors.blueGray, 0.85)};
  }
`

const VehicleShowcaseStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.darkNavy};
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${borderDecor}
  
  ${props => props.popupMode && css`
    @media (${media.maxPhone}) {
      ${ButtonNavigationStyled} {
        top: ${({ innerHeight }) => innerHeight}px;
        bottom: auto;
        transform: translateY(-100%);
      }

      ${LoaderStyled} {
        top: ${({ innerHeight }) => innerHeight}px;
        bottom: auto;

        &.show {
          transform: translateY(-100%);
        }
      }
    }

    ${VehicleShowcaseTabListStyled} {
      height: ${({ theme }) => theme.sizes.vehicleShowcaseNavHeight};
    }

    ${VehicleShowcaseNavStyled} {
      top: ${({ theme }) => theme.gutter.gutter96};

    }

    @media (${media.tablet}) {
      height: 100%;

      ${VehicleShowcaseNavStyled} {
        top: ${({ theme }) => theme.gutter.gutter32};
      }   
    }    
  `}

  @media (${media.tablet}) {
    background: ${({ theme }) => theme.colors.blueGray};
  }  
`

const VehicleShowcaseTabStyled = styled(Tab)`
  display: flex;
  background: transparent;
  border: none;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.gutter.gutter12};
  }

  &.react-tabs__tab--selected {
    background: transparent;
    color: inherit;

    ${VehicleShowcaseNavButtonStyled} {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }

  @media (${media.phone}) {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.gutter.gutter24};
    }
  }
`

const VehicleShowcaseTabPanelStyled = styled(TabPanel)``

const VehicleShowcaseTabsStyled = styled(Tabs)`
  width: 100%;
`

const EnchancedVehicleShowcaseStyled = withInnerHeight(VehicleShowcaseStyled)

export {
  EnchancedVehicleShowcaseStyled as VehicleShowcaseStyled,
  VehicleShowcaseLogoStyled,
  VehicleShowcaseNavStyled,
  VehicleShowcaseNavButtonStyled,
  VehicleShowcaseTabListStyled,
  VehicleShowcaseTabPanelStyled,
  VehicleShowcaseTabStyled,
  VehicleShowcaseTabsStyled,
}
