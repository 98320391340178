import React from 'react'
import PropTypes from 'prop-types'

import {
  VehicleExternalTeaserStyled,
  VehicleExternalTeaserImgStyled,
  VehicleExternalTeaserMediaStyled,
  VehicleExternalTeaserContentStyled,
  VehicleExternalTeaserHeaderStyled,
  VehicleExternalTeaserOverlayStyled,
  VehicleExternalTeaserBodyStyled,
  VehicleExternalTeaserDescriptionStyled,
} from './styles'
import { Heading4, Typo4 } from '../Typography'
import ButtonLink from '../Buttons/ButtonLink'
import { SIZES } from '../../constants/sizes'
import { KINDS } from '../../constants/kinds'
import { useTeaserImageSrc } from '../../common/utils'

const VehicleExternalTeaser = ({
  heading,
  price,
  url,
  src,
  srcPhone,
  srcSmallPhone,
  ...rest
}) => {
  const teaserSrc = useTeaserImageSrc(src, srcPhone, srcSmallPhone)

  return (
    <VehicleExternalTeaserStyled>
      <VehicleExternalTeaserMediaStyled
        href={url}
        target="_blank"
        rel="noopener"
        aria-label="View on citygate.co.uk"
      >
        <VehicleExternalTeaserOverlayStyled />
        <VehicleExternalTeaserImgStyled
          {...rest}
          src={teaserSrc}
        />
      </VehicleExternalTeaserMediaStyled>
      <VehicleExternalTeaserBodyStyled>
        <VehicleExternalTeaserContentStyled>
          <VehicleExternalTeaserHeaderStyled>
            <Heading4>{heading}</Heading4>
          </VehicleExternalTeaserHeaderStyled>
          <VehicleExternalTeaserDescriptionStyled>
            <Typo4>Buy now {price}</Typo4>
          </VehicleExternalTeaserDescriptionStyled>
          <ButtonLink
            href={url}
            target="_blank"
            rel="noopener"
            type={KINDS.rounded}
            size={SIZES.big}
            aria-label="View on citygate.co.uk"
          >
            View on citygate.co.uk
          </ButtonLink>
        </VehicleExternalTeaserContentStyled>
      </VehicleExternalTeaserBodyStyled>
    </VehicleExternalTeaserStyled>
  )
}

VehicleExternalTeaser.defaultProps = {
  price: '',
  url: '',
}

VehicleExternalTeaser.propTypes = {
  src: PropTypes.string.isRequired,
  srcPhone: PropTypes.string.isRequired,
  srcSmallPhone: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  price: PropTypes.string,
  url: PropTypes.string,
}

export default VehicleExternalTeaser
