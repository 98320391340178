import styled from 'styled-components'
import Img from 'gatsby-image'

import media from '../../common/MediaQueries'
import { InnerStyled } from '../../common/common'

const ColsSectionWrapperStyled = styled.div`
  position: relative;
  overflow: hidden;
  background: ${props => props.bg};
  padding-top: ${({ theme }) => theme.gutter.gutter32};
  padding-bottom: ${({ theme }) => theme.gutter.gutter32};

  @media (${media.phone}) {
    padding-top: ${({ theme }) => theme.gutter.gutter64};
    padding-bottom: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    padding-top: ${({ theme }) => theme.gutter.gutter160};
    padding-bottom: ${({ theme }) => theme.gutter.gutter128};
  }
`

const ColsSectionInnerStyled = styled(InnerStyled)`
  @media (${media.phone}) {
    padding-left: ${({ theme }) => theme.gutter.gutter96};
    padding-right: ${({ theme }) => theme.gutter.gutter96};
  }

  @media (${media.tablet}) {
    padding-left: ${({ theme }) => theme.gutter.gutter32};
    padding-right: ${({ theme }) => theme.gutter.gutter32};
  }
`

const ColsSectionContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.gutter.gutter40};

  @media (${media.phone}) {
    grid-gap: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    grid-template-columns: 
      ${({ theme }) => theme.sizes.colsSectionAsideWidth}
      1fr;
    grid-gap: ${({ theme }) => theme.gutter.gutter140};

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: ${({ theme }) => theme.sizes.colsSectionAsideWidth}
    ${({ theme }) => theme.gutter.gutter140} 1fr;  
  }
`

const ColsSectionAsideStyled = styled.div`
  /* IE */
  -ms-grid-column: 1;
`

const ColsSectionMainStyled = styled.div`
  /* IE */
  -ms-grid-column: 3;
`

const ColsSectionDecorImgWrapperStyled = styled.div`
  display: none;

@media (${media.tablet}) {
  display: block;
  position: absolute;
  top: -2px;
  right: -${({ theme }) => theme.gutter.gutter8};
}
`

const ColsSectionDecorImgStyled = styled(Img)`
`

export {
  ColsSectionWrapperStyled,
  ColsSectionInnerStyled,
  ColsSectionContentStyled,
  ColsSectionAsideStyled,
  ColsSectionMainStyled,
  ColsSectionDecorImgWrapperStyled,
  ColsSectionDecorImgStyled,
}
