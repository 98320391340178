/* eslint-disable max-len */
import React from 'react'

import {
  BenefitsListStyled,
  BenefitsListItemStyled,
  BenefitsListBoxStyled,
  BenefitsListHeaderStyled,
  BenefitsListIconStyled,
  BenefitsListTitleStyled,
  BenefitsListContentStyled,
} from './styles'
import { Heading6, Typo4 } from '../Typography'
import Square from '../Icons/Square'
import Stock from '../Icons/Stock'
import Upload from '../Icons/Upload'
import Car from '../Icons/Car'
import Turn from '../Icons/Turn'
import Pound from '../Icons/Pound'

const BenefitsListItems = [
  {
    icon: Square,
    title: 'Improved Efficiency',
    content: 'It really is as simple as pressing a button. Increase productivity, reduce human errors and no more hassle of manually uploading your vehicle imagery to your website. Automate your process and gain back valuable time.',
  },
  {
    icon: Pound,
    title: 'Providing what customers want',
    content: `Research by Frost & Sullivan predicts that more than 70% of vehicle sales leads will be digital by 2023
    and with more than half of today's internet shoppers browsing by mobile, and car buyers already spending 59% of
    their time online, it more important than ever to have a strong online presence.`,
  },
  {
    icon: Stock,
    title: 'Reduced Costs',
    content: `Vast reduction in manpower, vehicle storage time and time from marketing to sale
    means savings which will dramatically increase ATV's and profit margin for your business.`,
  },
  {
    icon: Upload,
    title: 'Instant upload',
    content: 'It takes one person as little as five minutes to photograph a vehicle and uploaded the results to your website. Spend less time placing your products online, and more time with your customers.',
  },
  {
    icon: Car,
    title: 'Increased Stock Turn',
    content: 'Reduced processing times coupled with stunning image results means a faster stock turn at a higher profit.',
  },
  {
    icon: Turn,
    title: 'Versatile',
    content: 'From a moped to a lorry - our turntables are capable of handling most vehicles and objects.',
  },
]

const BenefitsList = () => (
  <BenefitsListStyled>
    {BenefitsListItems.map(({ icon: Icon, title, content }) => (
      <BenefitsListItemStyled key={title}>
        <BenefitsListBoxStyled>
          <BenefitsListHeaderStyled>
            <BenefitsListIconStyled>
              <Icon />
            </BenefitsListIconStyled>
            <BenefitsListTitleStyled>
              <Heading6 as="h4">{title}</Heading6>
            </BenefitsListTitleStyled>
          </BenefitsListHeaderStyled>
          <BenefitsListContentStyled>
            <Typo4>{content}</Typo4>
          </BenefitsListContentStyled>
        </BenefitsListBoxStyled>
      </BenefitsListItemStyled>
    ))}
  </BenefitsListStyled>
)

export default BenefitsList
