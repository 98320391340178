import React, { useState, useMemo } from 'react'
import { Swiper, Slide } from 'react-dynamic-swiper'
import PropTypes from 'prop-types'

import {
  ClientQuotesSliderStyled,
  ClientQuotesSliderNavPrevStyled,
  ClientQuotesSliderNavNextStyled,
  ClientQuotesPaginationStyled,
  ClientQuotesPaginationInnerStyled,
  ClientQuotesPaginationLabelStyled,
  ClientQuotesPaginationCounterStyled,
} from './styles'
import ClientQuote from '../ClientQuote'
import ArrowLeftIcon from '../Icons/ArrowLeft'
import ArrowRightIcon from '../Icons/ArrowRight'
import { fluidPropType } from '../../constants/proptypes'

const prevButton = (
  <ClientQuotesSliderNavPrevStyled
    aria-label="prev"
  >
    <ArrowLeftIcon />
  </ClientQuotesSliderNavPrevStyled>
)
const nextButton = (
  <ClientQuotesSliderNavNextStyled
    aria-label="next"
  >
    <ArrowRightIcon />
  </ClientQuotesSliderNavNextStyled>
)

const renderSlide = ({
  heading,
  fluid,
  alt,
  label,
  logo,
}) => (
  <Slide key={alt}>
    <ClientQuote
      fluid={fluid}
      alt={alt}
      label={label}
      logo={logo}
    >
      {heading}
    </ClientQuote>
  </Slide>
)

const ClientQuoteSlider = ({
  slidesArray,
}) => {
  const [currentSlide, setCurrentSlide] = useState(1)
  const swiperOptions = useMemo(() => ({
    slidesPerView: 1,
    on: {
      slideChange() {
        // eslint-disable-next-line react/no-this-in-sfc
        setCurrentSlide(this.activeIndex + 1)
      },
    },
  }), [])
  const slides = useMemo(() => slidesArray.map(renderSlide), [slidesArray])

  return (
    <ClientQuotesSliderStyled>
      <ClientQuotesPaginationStyled>
        <ClientQuotesPaginationInnerStyled>
          <ClientQuotesPaginationLabelStyled>
            Client quotes
          </ClientQuotesPaginationLabelStyled>
          <ClientQuotesPaginationCounterStyled>
            {currentSlide}
            <span>/</span>
            {slidesArray.length}
          </ClientQuotesPaginationCounterStyled>
        </ClientQuotesPaginationInnerStyled>
      </ClientQuotesPaginationStyled>
      <Swiper
        swiperOptions={swiperOptions}
        pagination={false}
        prevButton={prevButton}
        nextButton={nextButton}
      >
        {slides}
      </Swiper>
    </ClientQuotesSliderStyled>
  )
}

ClientQuoteSlider.defaultProps = {
  sliderOptions: {},
}

ClientQuoteSlider.propTypes = {
  slidesArray: PropTypes.arrayOf(PropTypes.shape({
    fluid: fluidPropType.isRequired,
    alt: PropTypes.string.isRequired,
    heading: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  })).isRequired,
  sliderOptions: PropTypes.shape({
    slidesPerView: PropTypes.number,
    spaceBetween: PropTypes.number,
    breakpoints: PropTypes.object,
  }),
}

export default ClientQuoteSlider
