import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '28',
  height: '34',
  viewBox: '0 0 28 34',
  body: (
    <path
      d="M28 34H0v-4h28v4zm-8-8H8V14H0L14 0l14 14h-8v12z"
      key="key-upload"
    />
  ),
  displayName: 'Upload',
})
