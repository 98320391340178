import styled from 'styled-components'

import media from '../../common/MediaQueries'

const Showcase360Styled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding-bottom: ${({ theme }) => theme.ratio.ratioDemo};;
  height: 0;
  overflow: hidden;
  z-index: 0;
  cursor: ${props => (
    props.isLoaded ? (props.isDragging ? 'grabbing' : 'grab') : 'initial')};


  & > div::after {
    content: '';
    pointer-events: none;
    position: absolute;
    opacity: 0;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${props => props.poster}) no-repeat center;
    background-size: cover;
    background-position: top;
    transform: translateZ(0);
  }

  &.poster > ::after {
    opacity: 1;
  }
  
  canvas {
    display: block;
    max-width: 100%;
  }

  @media (${media.tablet}) {
    width: ${props => (props.windowRatio > 0.6667
    ? `${(props.windowRatio / 0.6667) * 100}%`
    : '100%')};
    padding-bottom: 0;
    height: 100%;
  }
`

export default Showcase360Styled
