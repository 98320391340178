import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '63',
  height: '63',
  viewBox: '0 0 63 63',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M19.944 55.946C11.51 51.975 5.477 43.774 4.549 34.079H.68c1.315 15.884 14.595 28.365 30.815 28.365l1.702-.077-9.825-9.825-3.43 3.404zM31.496.556l-1.702.077 9.825 9.825 3.43-3.43C51.48 11.025 57.514 19.2 58.416 28.896h3.868C60.996 13.036 47.715.556 31.496.556z"
      opacity="0.3"
      key="key-spin-active-arrows"
    />
  ),
  displayName: 'IconSpinActivateArrows',
})
