import styled from 'styled-components'
import 'react-dynamic-swiper/lib/styles.css'

import media from '../../common/MediaQueries'
import { transition, icon } from '../../common/Mixins'

const ClientQuotesSliderNavStyled = styled.button`
  position: absolute;
  top: 288px;
  right: 0;
  z-index: 1;
  width: ${({ theme }) => theme.sizes.quotesSliderNavPhoneSize};
  height: ${({ theme }) => theme.sizes.quotesSliderNavPhoneSize};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transform: translateY(-100%);
  ${transition}

  svg {
    ${icon}
    font-size: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.darkNavy};
  }

  @media (${media.phone}) {
    top: 500px;
    transform: none;
    width: ${({ theme }) => theme.sizes.quotesSliderNavSize};
    height: ${({ theme }) => theme.sizes.quotesSliderNavSize};

    svg {
      font-size: 16px;
    }
  }

  @media (${media.tablet}) {
    top: 0;
  }
`

const ClientQuotesPaginationStyled = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationPhoneWidth};
  height: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationPhoneHeight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background: ${({ theme }) => theme.colors.white};
  font-size: 11px;

  @media(${media.phone}) {
    width: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationTabletWidth};
    height: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationTabletHeight};
    font-size: 12px;
  }

  @media (${media.tablet}) {
    width: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationWidth};
    height: ${({ theme }) => theme.sizes.clientQuotesSliderPaginationHeight};
  }
`

const ClientQuotesPaginationInnerStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  writing-mode: tb-rl;
  padding-left: ${({ theme }) => theme.gutter.gutter10};
  width: inherit;
  height: inherit;
`

const ClientQuotesSliderStyled = styled.div`
  position: relative;
  margin: 0 ${({ theme }) => theme.gutter.gutter16};
  background: ${({ theme }) => theme.colors.blueGrayLight};

  .swiper-slide {
    display: flex;
    height: auto;
  }

  @media (${media.phone}) {
    margin: 0 ${({ theme }) => theme.gutter.gutter32};
  }

  @media (${media.tablet}) {
    margin: 0;
  }
`

const ClientQuotesSliderNavPrevStyled = styled(ClientQuotesSliderNavStyled)`
  transform: translateX(-100%) translateX(1px) translateY(-100%);
  background: ${({ theme }) => theme.colors.navy};

  @media(${media.phone}) {
    transform: translateX(-100%) translateX(1px);
  }
`

const ClientQuotesSliderNavNextStyled = styled(ClientQuotesSliderNavStyled)`
  background: ${({ theme }) => theme.colors.cobalt};
`

const ClientQuotesPaginationLabelStyled = styled.p`
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.gutter.gutter56};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};

  &::after {
    content: '';
    width: 80%;
    height: 1px;
    background: ${({ theme }) => theme.colors.cobalt};
    position: absolute;
    bottom: -${({ theme }) => theme.gutter.gutter18};
    right: 1px;
    display: flex;
    align-items: center;
  }
`

const ClientQuotesPaginationCounterStyled = styled.p`
  height: ${({ theme }) => theme.sizes.clientQuotePaginationCounterSize};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing50};

  > span {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      transform: translateX(3px);
    }
  }
`

export {
  ClientQuotesSliderStyled,
  ClientQuotesSliderNavPrevStyled,
  ClientQuotesSliderNavNextStyled,
  ClientQuotesPaginationStyled,
  ClientQuotesPaginationInnerStyled,
  ClientQuotesPaginationLabelStyled,
  ClientQuotesPaginationCounterStyled,
}
