import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  HeadingBoxStyled,
  HeadingBoxLabelStyled,
  HeadingBoxTextStyled,
  HeadingBoxActionStyled,
} from './styles'
import Button from '../Buttons/Button'
import { KINDS } from '../../constants/kinds'
import { Heading3, Typo7 } from '../Typography'

const HeadingBox = ({
  label,
  children,
  btnLabel,
  onClick,
}) => (
  <HeadingBoxStyled>
    <HeadingBoxLabelStyled>
      <Typo7>{label}</Typo7>
    </HeadingBoxLabelStyled>
    <HeadingBoxTextStyled>
      <Heading3 as="h3">{children}</Heading3>
    </HeadingBoxTextStyled>
    <HeadingBoxActionStyled>
      <Button
        onClick={onClick}
        kind={KINDS.outlineDark}
      >
        {btnLabel}
      </Button>
    </HeadingBoxActionStyled>
  </HeadingBoxStyled>
)

HeadingBox.defaultProps = {
  onClick: _noop,
}

HeadingBox.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  btnLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default HeadingBox
