import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '36',
  height: '36',
  viewBox: '0 0 36 36',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M32 36h-8v-4h8v-8h4v8a4 4 0 01-4 4zm-20 0H4a4 4 0 01-4-4v-8h4v8h8v4zm6-12a5.992 5.992 0 114.245-1.755A5.963 5.963 0 0118 24zm18-12h-4V4h-8V0h8a4 4 0 014 4v8zM4 12H0V4a4 4 0 014-4h8v4H4v8z"
      key="key-square"
    />
  ),
  displayName: 'Square',
})
