import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _first from 'lodash/first'

import PopupModal from '../PopupModal'
import Hero from '../../components/Hero'
import VehicleShowcase from '../../components/VehicleShowcase'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import { fluidPropType } from '../../constants/proptypes'

const HeroWrapper = ({ heros, heroVehicle, initialHeroPopupOpened }) => {
  const [isOpened, setIsOpened] = useState(initialHeroPopupOpened)

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(event => {
    event.preventDefault()
    setIsOpened(true)
  }, [setIsOpened])

  const {
    exteriorImages: assets360,
    assets: slidesImages,
    interiorImages,
    popupUrl,
  } = heroVehicle

  return (
    <>
      <Hero
        heros={heros}
        popupUrl={popupUrl}
        onClick={openModal}
      />
      <PopupModal
        closeTimeoutMS={370}
        isOpen={isOpened}
        closeModal={closeModal}
        logo={(
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.mixedLight}
          />
        )}
        popupUrl={popupUrl}
      >
        <VehicleShowcase
          popupMode
          closeModal={closeModal}
          assets360={assets360}
          poster={_first(assets360)}
          interiorImage={interiorImages}
          sliderImages={slidesImages}
        />
      </PopupModal>
    </>
  )
}

HeroWrapper.defaultProps = {
  initialHeroPopupOpened: false,
}

HeroWrapper.propTypes = {
  heros: PropTypes.arrayOf(fluidPropType).isRequired,
  initialHeroPopupOpened: PropTypes.bool,
  heroVehicle: PropTypes.shape({
    exteriorImages: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
    interiorImages: PropTypes.string,
    popupUrl: PropTypes.string,
  }).isRequired,
}

export default HeroWrapper
