import React from 'react'
import PropTypes from 'prop-types'

import ButtonLinkStyled from './styles'
import { Typo6 } from '../../Typography'
import { SIZES } from '../../../constants/sizes'
import { KINDS } from '../../../constants/kinds'

const ButtonLink = ({
  children,
  ...rest
}) => (
  <ButtonLinkStyled
    {...rest}
  >
    <Typo6>{children}</Typo6>
  </ButtonLinkStyled>
)

ButtonLink.defaultProps = {
  kind: 'rounded',
  size: 'medium',
}

ButtonLink.propTypes = {
  kind: PropTypes.oneOf(Object.keys(KINDS)),
  size: PropTypes.oneOf(Object.keys(SIZES)),
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ButtonLink
