import React from 'react'

import {
  SoftwareStyled,
  SoftwareContentStyled,
  SoftwareMediaStyled,
  SoftwareDecorStyled,
  SoftwareImgStyled,
  SoftwareActionStyled,
  SoftwareBottomStyled,
  SoftwareDescStyled,
  SoftwareLogoStyled,
} from './styles'
import { SimpleLinkStyled } from '../../common/common'
import ContentBox from '../ContentBox'
import ButtonLinkScroll from '../Buttons/ButtonLinkScroll'
import DecorBg from '../../assets/images/bg-decor.svg'
import Notebook from '../../assets/images/img-notebook.png'
import { Typo4, Typo8 } from '../Typography'
import AutoWorksLogo from '../../assets/logos/autoworks.svg'
import MENU from '../../constants/menu'
import { KINDS } from '../../constants/kinds'
import { SIZES } from '../../constants/sizes'

const Software = () => (
  <SoftwareStyled>
    <SoftwareContentStyled bg={DecorBg}>
      <ContentBox header="Automated 360 degree Vehicle photography studios and imaging software">
        <>
          <Typo4>
            We provide turnkey solutions to capture all the 360 degree content
            necessary to create high impact web listings of your vehicles. Our
            solution comprises the design and build of a vehicle photography
            studio to be used in conjunction with our dedicated AutoloadIT Image
            Control Centre software. This powerful combination streamlines and
            deskills the vehicle photography process while yielding industry
            leading image results at the push of a button.
          </Typo4>
          <Typo4>
            Using our AutoloadIT software means you will get the best from our
            system as it will:
          </Typo4>
          <ul>
            <li>
              <Typo4>
                Identify the vehicle via ANPR (registration recognition
                capability) or a simple stocklist.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Optimise our True Colour LED lighting to attain the best image
                results for every car.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Accurately control the Turntable to ensure consistent vehicle
                positioning for your hero shots.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Collate the external, interior, detail images and video clips
                into one cohesive image set.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Upload the image set to server, AWS or local drive path as
                specified.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Create a backup copy of the image set on the local PC.
              </Typo4>
            </li>
            <li>
              <Typo4>
                Create an online report of all processed vehicles for the
                policing of image quality and stats.
              </Typo4>
            </li>
          </ul>
        </>
      </ContentBox>
      <SoftwareActionStyled>
        <ButtonLinkScroll
          to={MENU.BENEFITS.to}
          spy
          smooth
          offset={-90}
          duration={500}
          type={KINDS.outlineDark}
          size={SIZES.medium}
        >
          Tell me more
        </ButtonLinkScroll>
      </SoftwareActionStyled>
    </SoftwareContentStyled>
    <SoftwareMediaStyled>
      <SoftwareDecorStyled />
      <SoftwareImgStyled src={Notebook} alt="Notebook" />
      <SoftwareBottomStyled>
        <SoftwareDescStyled>
          <Typo8>Recommended SOFTWARE</Typo8>
        </SoftwareDescStyled>
        <SimpleLinkStyled
          href="https://auto.works"
          target="_blank"
          rel="noopener"
        >
          <SoftwareLogoStyled src={AutoWorksLogo} alt="AutoWotks" />
        </SimpleLinkStyled>
      </SoftwareBottomStyled>
    </SoftwareMediaStyled>
  </SoftwareStyled>
)

export default Software
