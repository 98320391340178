import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '25',
  height: '25',
  viewBox: '0 0 25 25',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M0 10.747h18.685l-8.582-8.582L12.283 0l12.282 12.283-12.282 12.282-2.165-2.164 8.567-8.583H0v-3.07z"
      key="key-arrow-right"
    />
  ),
  displayName: 'ArrowRight',
})
