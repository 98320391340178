import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '25',
  height: '25',
  viewBox: '0 0 25 25',
  body: (
    <path
      d="M24.6 13.8H5.9l8.6 8.6-2.2 2.2L0 12.3 12.3 0l2.2 2.2-8.6 8.6h18.7v3z"
      key="key-arrow-left"
    />
  ),
  displayName: 'ArrowLeft',
})
