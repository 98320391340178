import React from 'react'
import _noop from 'lodash/noop'
import { Swiper, Slide } from 'react-dynamic-swiper'
import PropTypes from 'prop-types'

import {
  types,
  VehicleTeasersSliderStyled,
  VehicleTeasersSliderNavPrevStyled,
  VehicleTeasersSliderNavNextStyled,
} from './styles'
import ArrowLeftIcon from '../Icons/ArrowLeft'
import ArrowRightIcon from '../Icons/ArrowRight'

const VehicleTeaserSlider = ({
  slidesArray,
  sliderOptions,
  type,
  slideComponent: SlideComponent,
  onSlideClick,
}) => {
  const renderSlide = slide => {
    const {
      id,
      heading,
      src,
      srcPhone,
      srcSmallPhone,
      alt,
      price,
      images,
      exteriorImages,
      interiorImages,
      url,
      popupUrl,
    } = slide

    return (
      <Slide key={id}>
        <SlideComponent
          src={src}
          srcPhone={srcPhone}
          srcSmallPhone={srcSmallPhone}
          alt={alt}
          heading={heading}
          price={price}
          assets={images}
          exteriorImages={exteriorImages}
          interiorImages={interiorImages}
          onClick={event => onSlideClick(event, slide)}
          popupUrl={popupUrl}
          url={url}
        />
      </Slide>
    )
  }

  return (
    <VehicleTeasersSliderStyled type={type}>
      <Swiper
        swiperOptions={sliderOptions}
        pagination={false}
        prevButton={(
          <VehicleTeasersSliderNavPrevStyled
            aria-label="prev"
          >
            <ArrowLeftIcon />
          </VehicleTeasersSliderNavPrevStyled>
        )}
        nextButton={(
          <VehicleTeasersSliderNavNextStyled
            aria-label="next"
          >
            <ArrowRightIcon />
          </VehicleTeasersSliderNavNextStyled>
        )}
      >
        {slidesArray.map(renderSlide)}
      </Swiper>
    </VehicleTeasersSliderStyled>
  )
}

VehicleTeaserSlider.defaultProps = {
  sliderOptions: {},
  type: 'one',
  onSlideClick: _noop,
}

VehicleTeaserSlider.propTypes = {
  slidesArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    srcPhone: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    price: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
  sliderOptions: PropTypes.shape({
    slidesPerView: PropTypes.number,
    spaceBetween: PropTypes.number,
    breakpoints: PropTypes.object,
  }),
  type: PropTypes.oneOf(Object.keys(types)),
  slideComponent: PropTypes.func.isRequired,
  onSlideClick: PropTypes.func,
}

export default VehicleTeaserSlider
