import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  AccordionListStyled,
  AccordionItemStyled,
  AccordionButtonStyled,
} from './styles'
import Accordion from '../Accordion'

const accordionItems = [
  {
    title: `1. How long does it take to photograph
    a vehicle using the AutoLoadIT system?`,
    content: `A full 360 degree spin in our standard studio design takes around
    1 minute. Normally there are additional image requirements such as an
    internal 360 and some static image shots to add. Depending on the image
    requirement, a vehicle can be imaged and the upload started in as little
    as 90 seconds. Uploading happens in the background, so the next vehicle can
    be started even if you have a very slow internet connection.`,
  },
  {
    title: '2. How many vehicles is it possible to process in a day?',
    content: `This will depend on a number of factors including your
    image set requirement, if you have drivers available to do the
    vehicle movements and whether you have space for a high end "drive-thru"
    studio.  Based on existing client reports, it will take as little as 5
    minutes per vehicle (that includes the vehicle movement) to photograph
    and upload an image set comprising a full 360 degree spin (36 images)
    plus 9 external handheld camera images.`,
  },
  {
    title: '3. How many people are required to operate the system?',
    content: `The AutoloadIT system requires just one operator, as
    many of the processes are fully automated. For high volume throughput
    (where more than 40 vehicles need to be photographed in a day) it is
    advisable to have a second operator to manage vehicle movements
    in and out of the studio.`,
  },
  {
    title: `4. Does the studio operator need
    to be a professional photographer?`,
    content: `No, most of our existing studios are managed by vehicle
    operatives. The AutoLoadIT system is designed from the ground up to simplify
    and minimise workload on the operator. Most of the operators
    we train are not technical people.`,
  },
  {
    title: `5. What is the maximum size of vehicle that
    can be photographed in an AutoloadIT studio?`,
    content: `If you have the space available or are willing to have a
    building built large enough to cater for them, we can build our studios as
    large as you need. Our turntables can be increased in size to accommodate
    larger vehicles. Even our standard 5.5m turntable can accommodate
    commercial vehicles such as the Volkswagen Crafter.`,
  },
  {
    title: '6. How much space is needed for a typical AutoLoadIT studio?',
    content: `We have managed to build studios into some of the most
    awkward spaces. You want to find a space at least 2m wider than your
    longest vehicle. It should also be about a meter higher than your tallest
    vehicle. And in length, we’ll take as much as you give us! You want your
    vehicles to look their best, the more space you give us, the better
    we’ll make them look.`,
  },
  {
    title: `7. Can the AutoLoadIT system integrate with our
    existing vehicle stock database?`,
    content: `Yes, if you have an existing vehicle stocklist and it can
    be provided to us in an API or in a simple csv or xml file, we can read it
    to ensure we identify the vehicles correctly and name the images correctly.
    Captured images are then assigned to the specific vehicle being photographed
    and then uploaded directly from the studio to wherever you specify e.g. to
    your DMS provider, to your website provider, to a dedicated web server, to
    an AWS S3 bucket or to Autotrader™ via their image only feed. We can also
    send notifications directly to your web provider.`,
  },
  {
    title: '8. What countries is this available to?',
    content: `Most! We have clients in over 20 countries -
    please get in touch if you need more information.`,
  },
  {
    title: `9. Can you photograph and upload a vehicles images if you don’t
    use a vehicle stocklist, or if the vehicle isn’t in the stocklist?`,
    content: `Yes. It will just ask the operator to manually
    enter the key information about the vehicle.`,
  },
  {
    title: '10. Image naming convention, is there any restrictions?',
    content: `No, there’s no restrictions other than avoiding special
    characters. As long as the vehicles data in the stocklist or entered
    by the operator contains the information you wish to include in the name,
    we can automatically name the images.`,
  },
  {
    title: `11. Can you identify/label which images are which e.g.
    Satellite navigation, DAB radio, Sun roof etc.?`,
    content: `Yes, we can show the operator how to identify
    the specific images and include that identification in the name.`,
  },
  {
    title: '12. If I’m a manager, will it tell me what’s been photographed?',
    content: `Yes, we provide you with an online report you can access from
    anywhere (with an internet connection). Not only will it tell you what’s
    been photographed in the studio, if you want to see the images, you can.
    So you can use it for quality control as well.`,
  },
  {
    title: `13. Does AutoLoadIT have ANPR
    (Automatic Number Plate Recognition)?`,
    content: `Yes. We can turn it on and off and the operator can always
    overrule the ANPR warning (they may be photographing a car that appears
    in the stocklist with a personalised plate, but at the time of
    photographing, has it’s new plate).`,
  },
  {
    title: '14. Will it intergrate with my website?',
    content: `Yes, we can upload images to wherever we are told and provide
    notification files or use an api to integrate with your existing systems.`,
  },
  {
    title: `15. Can the AutoloadIT system handle image uploads to more than one
    point e.g. native website and auction platform simultaneously?`,
    content: `Yes, images can be uploaded to multiple points simultaneously.
    If you are imaging on behalf of multiple clients, the operator would select
    the client and then photograph the vehicle. Each client has their own
    settings for stocklist, upload locations, notifications etc.`,
  },
  {
    title: '16. Can the studio be built outdoors?',
    content: `We wouldn’t recommend an outdoor studio, although the turntables
    are suitable for outdoor use and we can use waterproof cameras. The problem
    with an outdoor studios is controlling the light and of course the weather,
    but if it’s the only practical solution for your requirement, then yes,
    we can build the studio outdoors.`,
  },
  {
    title: '17. Can the studio be inside a poly tunnel building?',
    content: 'Yes, we have built studios inside this type of building.',
  },
  {
    title: '18. How much will it cost?',
    content: `Sorry not to be able to give a simple answer, but that’s
    impossible to say without knowing more. For example, if you have an
    existing building that lends itself to use as a studio then you’ll only
    need the turntable, lighting, backdrop, camera(s) and software. If you
    need us to provide the entire studio, then it’s going to be more. If you
    only photograph a few vehicles a week, the studio design, the cameras we
    use and the lighting are very different from a design where you need to
    photograph 70 cars per day. Therefore, we really need to understand your
    requirement, your existing building (if you have one), and any existing
    equipment you might already have that we can use e.g. suitable cameras.`,
    hasModal: true,
  },
  {
    title: '19. I have an existing turntable, can you use it?',
    content: 'Yes, we’ve converted lots of third party turntables.',
  },
  {
    title: '20. I have an existing studio, can I use the AutoLoadIT software?',
    content: `Yes, we’ve converted a number of existing third party studios,
    including taking control of the turntables.`,
  },
  {
    title: `21. What happens if something goes wrong? Is "on call"
    technical / service support available?`,
    content: `Yes, we have dedicated specialists which are able to access the
    studio PC workstation directly (via remote connection) to assist the
    operator with any upload / image capture issues. We also have a team of
    dedicated service engineers available for any mechanical mishaps.`,
  },
  {
    title: '22. What requirements do I need to have this installed?',
    content: `The main thing you will need is space, but how much will depend on
    your vehicle requirements, both in terms of volume thru-put and max vehicle
    size. The area you select should be dry, not exposed to extremes of
    temperature and humidity. Near to the valeting area is a good idea. We have
    built a number of studios in purpose built outdoor buildings.`,
  },
  {
    title: '23. What about post-production?',
    content: `No post production/Photoshop is needed or recommended! We pride
    ourselves on the perfect set up, meaning there is no need for post
    production and no vehicles will have the 'banana' fisheye effect. In fact,
    every car you see on this site has been photographed using our systems with
    no post production. It is our belief that if images are "touched-up",
    enhanced or tampered with in any way, you will lose the trust of the
    potential buyer. What they see should be, what they buy. Statistics from the
    major UK vehicle portals confirm these views, do not retouch the images!`,
  },
  {
    title: `24. What happens if the system doesn't recognise a number plate?`,
    content: `There will be occasions where entering the vehicle registration
    won't locate the vehicle in the stocklist. This is usually due to a private
    registration being used or the data may simply have been captured
    incorrectly at source. In either case, our system allows
    the operator to manually enter the key vehicle data to ensure they
    can continue to photograph the vehicle.`,
  },
]

const AccordionList = ({ openModal }) => (
  <AccordionListStyled>
    {accordionItems.map(({ title, content, hasModal }) => (
      <AccordionItemStyled key={title}>
        <Accordion title={title}>
          {content}{' '}
          {hasModal && (
            <AccordionButtonStyled onClick={openModal}>
              Get in touch
            </AccordionButtonStyled>
          )}
        </Accordion>
      </AccordionItemStyled>
    ))}
  </AccordionListStyled>
)

AccordionList.propTypes = {
  openModal: PropTypes.func,
}

AccordionList.defaultProps = {
  openModal: _noop,
}

export default AccordionList
