import React from 'react'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  VehicleTeaserStyled,
  VehicleTeaserImgStyled,
  VehicleTeaserMediaStyled,
  VehicleTeaserContentStyled,
  VehicleTeaserBodyStyled,
  VehicleTeaserActionStyled,
  VehicleTeaserOverlayStyled,
} from './styles'
import { Heading4 } from '../Typography'
import ExpandIcon from '../Icons/Expand'
import ButtonLink from '../Buttons/ButtonLink'
import { KINDS } from '../../constants/kinds'
import { SIZES } from '../../constants/sizes'
import { useTeaserImageSrc } from '../../common/utils'

const VehicleTeaser = ({
  heading,
  onClick,
  src,
  srcPhone,
  srcSmallPhone,
  popupUrl,
  ...rest
}) => {
  const teaserSrc = useTeaserImageSrc(src, srcPhone, srcSmallPhone)

  return (
    <VehicleTeaserStyled>
      <VehicleTeaserMediaStyled
        onClick={onClick}
      >
        <VehicleTeaserOverlayStyled />
        <VehicleTeaserImgStyled
          {...rest}
          src={teaserSrc}
        />
      </VehicleTeaserMediaStyled>
      <VehicleTeaserBodyStyled>
        <VehicleTeaserContentStyled>
          <Heading4>
            {heading}
            <span>360°</span>
          </Heading4>
        </VehicleTeaserContentStyled>
        <VehicleTeaserActionStyled>
          <ButtonLink
            href={popupUrl}
            onClick={onClick}
            kind={KINDS.withIcon}
            size={SIZES.large}
            aria-label="View fullscreen demo"
          >
            View fullscreen demo
            <ExpandIcon />
          </ButtonLink>
        </VehicleTeaserActionStyled>
      </VehicleTeaserBodyStyled>
    </VehicleTeaserStyled>
  )
}

VehicleTeaser.defaultProps = {
  onClick: _noop,
  popupUrl: '',
}

VehicleTeaser.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  srcPhone: PropTypes.string.isRequired,
  srcSmallPhone: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  popupUrl: PropTypes.string,
}

export default VehicleTeaser
