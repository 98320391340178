import React, { useContext } from 'react'
import { InnerHeightContext } from '.'

const withInnerHeight = InnerComponent => ({ children, ...props }) => {
  const innerHeight = useContext(InnerHeightContext)

  return (
    <InnerComponent innerHeight={innerHeight} {...props}>
      {children}
    </InnerComponent>
  )
}

export default withInnerHeight
