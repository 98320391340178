import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { transition } from '../../common/Mixins'

const AccordionListStyled = styled.ul`
  @media (${media.phone}) {
    margin-left: -${({ theme }) => theme.gutter.gutter64};
    margin-right: -${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    margin-left: 0;
    margin-right: 0;
  }
`

const AccordionItemStyled = styled.li`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter16};
  }

  @media (${media.phone}) {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.gutter.gutter24};
    }
  }

  @media (${media.tablet}) {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.gutter.gutter28};
    }
  }
`

const AccordionButtonStyled = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkNavy};
  ${transition}

  &:hover {
    color: ${({ theme }) => theme.colors.arctic};
  }
`

export {
  AccordionListStyled,
  AccordionItemStyled,
  AccordionButtonStyled,
}
