import React from 'react'
import { makeSvgIcon } from './utils'

export default makeSvgIcon({
  width: '36',
  height: '31',
  viewBox: '0 0 36 31',
  body: (
    <path
      // eslint-disable-next-line max-len
      d="M34 31h-2a1.972 1.972 0 01-2-1.937v-1.938H6v1.938A1.972 1.972 0 014 31H2a1.971 1.971 0 01-2-1.937v-15.5L4.16 1.957A2.987 2.987 0 017 0h22a2.986 2.986 0 012.84 1.957L36 13.562v15.5A1.971 1.971 0 0134 31zm-5-15a3 3 0 103 3 3 3 0 00-3-3zM7 16a3 3 0 103 3 3 3 0 00-3-3zM7 3l-3 9h28l-3-9z"
      key="key-car"
    />
  ),
  displayName: 'Car',
})
