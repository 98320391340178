import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { rgba } from 'polished'

import { InnerStyled } from '../../common/common'
import media, { customMedia } from '../../common/MediaQueries'

const HeroStyled = styled.div`
  position: relative;
  display: flex;

  ${InnerStyled} {
    display: flex;
    flex-direction: column;
    padding-top: ${({ theme }) => theme.sizes.heroPaddingTopPhone};
    min-height: ${({ theme }) => theme.sizes.heroHeightMobile};
    height: 100vh;

    @media (${media.phone}) {
      padding-top: ${({ theme }) => theme.sizes.heroPaddingTopTablet};
      min-height: ${({ theme }) => theme.sizes.heroHeight};
    }

    @media (${media.tablet}) {
      padding-top: ${({ theme }) => theme.sizes.heroPaddingTop};
    }

    @media (${media.desktop}) {
      min-height: ${({ theme }) => theme.sizes.heroHeightDesktop};
    }
  }
`

const HeroImgWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HeroImgStyled = styled(Img)`
  height: 100%;
`

const HeroGradientStyled = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${({ theme }) => theme.sizes.heroGradientHeightPhone};
  background-image: linear-gradient(
    transparent,
    ${({ theme }) => rgba(theme.colors.navy, 0.23)});
  
  @media (${media.tablet}) {
    height: ${({ theme }) => theme.sizes.heroGradientHeight};
  }
`

const HeroBodyStyled = styled.div`
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.heroBodyWidth};

  @media (${media.tablet}) {
    max-width: 100%;
  }
`

const HeroHeaderStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.gutter.gutter8};

  span {
    display: block;
    color: ${({ theme }) => theme.colors.arctic};

    @media (${media.tablet}) {
      display: inline;
    }
  }

  @media (${media.tablet}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter16};
  }
`

const HeroDescStyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};
  max-width: ${({ theme }) => theme.sizes.heroHeaderWidth};
  width: 100%;
`

const HeroActionsStyled = styled.div``

const HeroContactDetailsStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.gutter.gutter28};

  @media (${customMedia.contactBreakpoint}) {
    display: none;
  }
`

export {
  HeroStyled,
  HeroImgWrapperStyled,
  HeroImgStyled,
  HeroGradientStyled,
  HeroBodyStyled,
  HeroHeaderStyled,
  HeroDescStyled,
  HeroActionsStyled,
  HeroContactDetailsStyled,
}
