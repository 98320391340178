import styled from 'styled-components'
import { Image } from 'rebass'

import media from '../../common/MediaQueries'

const ImageGalleryMediaStyled = styled(Image)`
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';  

  @media (${media.tablet}) {
    width: ${props => (props.windowRatio > 0.6667
    ? `${(props.windowRatio / 0.6667) * 100}%`
    : '100%')};
    position: static;
    top: 0;
    left: 0;
    transform: none;
    max-width: none;
  }
`

const ImageGallerySliderStyled = styled.div`
  ${ImageGalleryMediaStyled} {
    @media (${media.tablet}) {
      height: 100vh;
    }
  }
`

const ImageGalleryMediaWrapperStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.ratio.ratioDemo};;
  height: 0;
  z-index: 0;
  
  @media (${media.tablet}) {
    height: auto;
    padding-bottom: 0;
  }
`

export {
  ImageGallerySliderStyled,
  ImageGalleryMediaStyled,
  ImageGalleryMediaWrapperStyled,
}
