import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from '../global/Layout'
import HeroWrapper from '../layouts/HeroWrapper'
import Partners from '../components/Partners'
import Footer from '../layouts/Footer'
import CommonSection from '../layouts/CommonSection'
import VehicleTeaserWrapper from '../layouts/VehicleTeaserWrapper'
import PartnerSection from '../layouts/PartnerSection'
import ClientQuotesSection from '../layouts/ClientQuotesSection'
import BenefitsWrapper from '../layouts/BenefitsWrapper'
import MediaBox from '../components/MediaBox'
import ContactWrapper from '../layouts/ContactWrapper'
import SoftwareWrapper from '../layouts/SoftwareWrapper'
import GetInTouchWrapper from '../layouts/GetInTouchWrapper'
import media from '../common/MediaQueries'

const IndexPage = ({ data, pageContext }) => {
  const teaserVehsSlides = _get(pageContext, 'data.teaserVehsSlides') || []
  const externalVehsSlides = _get(pageContext, 'data.externalVehsSlides') || []
  const heroDemoVehicle = _get(pageContext, 'data.heroDemoVehicle') || {}

  const {
    initialHeroPopupOpened,
    initialTeaserPopupVehicle,
    initialContactPopupOpened,
  } = _get(pageContext, 'popupsData') || {}

  const heros = [
    data.heroPhone.childImageSharp.fluid,
    {
      ...data.hero.childImageSharp.fluid,
      media: `(${media.tablet})`,
    },
  ]

  return (
    <Layout>
      <ContactWrapper
        car={data.carThree.childImageSharp.fluid}
        initialContactPopupOpened={initialContactPopupOpened}
      />
      <HeroWrapper
        heros={heros}
        heroVehicle={heroDemoVehicle}
        initialHeroPopupOpened={initialHeroPopupOpened}
      />
      <Partners />
      <CommonSection>
        <MediaBox
          carOne={data.carOne.childImageSharp.fluid}
          carTwo={data.carTwo.childImageSharp.fluid}
        />
      </CommonSection>
      <VehicleTeaserWrapper
        teaserVehicles={teaserVehsSlides}
        initialTeaserPopupVehicle={initialTeaserPopupVehicle}
      />
      <SoftwareWrapper car={data.carThree.childImageSharp.fluid} />
      <ClientQuotesSection images={data.allClients.edges} />
      <BenefitsWrapper
        benefitsVehicle={heroDemoVehicle}
        initialBenefitsPopupOpened={initialHeroPopupOpened}
      />
      <PartnerSection teaserVehicles={externalVehsSlides} />
      <GetInTouchWrapper
        car={data.carThree.childImageSharp.fluid}
        decor={data.autoloadit.childImageSharp.fixed}
      />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    carOne: file(relativePath: { eq: "img-car-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    carTwo: file(relativePath: { eq: "img-lens.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    carThree: file(relativePath: { eq: "img-car-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allClients: allImageSharp(
      filter: { original: { src: { regex: "/client/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    hero: file(relativePath: { eq: "img-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroPhone: file(relativePath: { eq: "img-hero-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 820, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    autoloadit: file(relativePath: { eq: "pic-autoloadit.png" }) {
      childImageSharp {
        fixed(width: 109) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
