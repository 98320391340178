import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { InnerStyled } from '../../common/common'

const CommonSectionStyled = styled.div`
  overflow: hidden;
  margin-top: ${({ theme }) => theme.gutter.gutter32};

  @media (${media.phone}) {
    margin-top: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    margin-top: ${({ theme }) => theme.gutter.gutter128};
    margin-bottom: ${({ theme }) => theme.gutter.gutter128};
  }
`

const CommonSectionInnerStyled = styled(InnerStyled)``

export {
  CommonSectionStyled,
  CommonSectionInnerStyled,
}
