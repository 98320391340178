import styled from 'styled-components'
import { rgba } from 'polished'
import media from '../../common/MediaQueries'

const PanoramaWrapperStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.ratio.ratioDemo};;
  height: 0;

  & > div::before,
  & > div::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    transition: opacity 1.5s 
      ${({ theme }) => theme.ease.ease_out_circ} ${props => (props.isLoading ? '0s' : '0.9s')};
  }

  & > div::before {
    opacity: 1;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.darkNavy};
    opacity: ${props => (props.isLoading ? 1 : 0)};
  }

  & > div::after {
    opacity: 0.3;
    z-index: 3;
    background: ${({ theme }) => rgba(theme.colors.darkNavy, 0.2)}
      url(${props => props.poster}) no-repeat center;
    background-size: cover;

    opacity: ${props => (props.isLoading ? 0.3 : 0)};
  }

  @media (${media.tablet}) {
    position: static;
    padding-bottom: 0;
    transform: none;
    height: 100%;

    & > div::before {
      background-color: ${({ theme }) => theme.colors.blueGray};
    }
    & > div::after {
      background: ${({ theme }) => rgba(theme.colors.blueGray, 0.2)}
    }
  }
`

export default PanoramaWrapperStyled
