import React from 'react'
import { array, func } from 'prop-types'
import _noop from 'lodash/noop'

import {
  LoaderStyled,
  ProgressStyled,
  ProgressBarStyled,
} from './styles'

class AssetLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 'hide',
      loadedAssetCount: 0,
    }
    this._loadImage = this._loadImage.bind(this)
  }

  get isComponentMounted() {
    return this.updater.isMounted(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      assets,
      onLoaded,
    } = this.props

    if (assets !== nextProps.assets) {
      this.setState({ active: 'show' })

      this._prefetchAssets(nextProps.assets).then(_array => {
        const _loadedImages = _array.map(_data => _data._img)
        onLoaded(_loadedImages)

        if (this.isComponentMounted) {
          this.setState({ active: 'hide' })
        }
      })
    }
  }

  // MARK: Asset Loading

  _loadImage(_path) {
    return new Promise(resolve => {
      const _img = new Image()
      _img.onload = () => {
        resolve({ _img, status: 'ok' })
        if (this.isComponentMounted) {
          this.setState(prevState => (
            { loadedAssetCount: prevState.loadedAssetCount + 1 }
          ))
        }
      }
      _img.onerror = () => resolve({ _img, status: 'error' })
      _img.src = _path
      return _img
    })
  }

  _prefetchAssets(_images) {
    return Promise.all(_images.map(this._loadImage))
  }

  render() {
    const {
      assets,
    } = this.props
    const {
      active,
      loadedAssetCount,
    } = this.state
    const progress = assets.length !== 0
      ? loadedAssetCount / assets.length
      : 0

    return (
      <LoaderStyled className={active}>
        <ProgressBarStyled percentage={`${progress}`} />
        <ProgressStyled />
      </LoaderStyled>
    )
  }
}

AssetLoader.defaultProps = {
  assets: [],
  onLoaded: _noop,
}

AssetLoader.propTypes = {
  assets: array,
  onLoaded: func,
}

export default AssetLoader
