import React, { useState } from 'react'
import { arrayOf, string, bool } from 'prop-types'

import {
  VehicleShowcaseStyled,
  VehicleShowcaseNavStyled,
  VehicleShowcaseNavButtonStyled,
  VehicleShowcaseTabListStyled,
  VehicleShowcaseTabPanelStyled,
  VehicleShowcaseTabStyled,
  VehicleShowcaseTabsStyled,
} from './styles'
import VehicleShowcase360 from '../VehicleShowcase360'
import VehicleShowcasePanoramaWrapper from '../VehicleShowcasePanoramaWrapper'
import ImageGallery from '../ImageGallery'
import { Typo5 } from '../Typography'
import NAVIGATION from '../../constants/navigation'
import withInnerHeight from '../../global/InnerHeightObserver/withInnerHeight'

const VehicleShowcase = (
  {
    popupMode,
    assets360,
    poster,
    interiorImage,
    sliderImages = [],
  }
) => {
  const [mode, setMode] = useState(0)
  const changeMode = addAmount => () => setMode(mode + addAmount)
  const hasInteriorImage = !!interiorImage
  const navigationItems = hasInteriorImage
    ? Object.values(NAVIGATION)
    : [NAVIGATION.EXTERIOR, NAVIGATION.IMAGES]
  const navigationLength = navigationItems.length
  const slidesLength = (navigationLength - 1) + sliderImages.length

  return (
    <VehicleShowcaseStyled
      popupMode={popupMode}
    >
      <VehicleShowcaseTabsStyled
        selectedIndex={mode > (navigationLength - 1)
          ? (navigationLength - 1) : mode}
        onSelect={setMode}
      >
        <VehicleShowcaseNavStyled>
          <VehicleShowcaseTabListStyled>
            {navigationItems.map(item => (
              <VehicleShowcaseTabStyled
                key={item}
              >
                <VehicleShowcaseNavButtonStyled>
                  <Typo5>{item}</Typo5>
                </VehicleShowcaseNavButtonStyled>
              </VehicleShowcaseTabStyled>
            ))}
          </VehicleShowcaseTabListStyled>
        </VehicleShowcaseNavStyled>
        <VehicleShowcaseTabPanelStyled>
          <VehicleShowcase360
            load
            assets={assets360}
            poster={poster}
            slidesLength={slidesLength}
            activeIndex={mode}
            onLeftClick={changeMode(-1)}
            onRightClick={changeMode(1)}
          />
        </VehicleShowcaseTabPanelStyled>
        {hasInteriorImage && (
          <VehicleShowcaseTabPanelStyled>
            <VehicleShowcasePanoramaWrapper
              image={interiorImage}
              slidesLength={slidesLength}
              activeIndex={mode}
              onLeftClick={changeMode(-1)}
              onRightClick={changeMode(1)}
            />
          </VehicleShowcaseTabPanelStyled>
        )}
        <VehicleShowcaseTabPanelStyled>
          <ImageGallery
            images={sliderImages}
            slidesLength={slidesLength}
            onPrevClick={index => index === 0 && setMode(interiorImage ? 1 : 0)}
            slideNumberDisplayOffset={interiorImage ? 2 : 1}
          />
        </VehicleShowcaseTabPanelStyled>
      </VehicleShowcaseTabsStyled>
    </VehicleShowcaseStyled>
  )
}

VehicleShowcase.defaultProps = {
  poster: '',
  assets360: [],
  interiorImage: '',
  sliderImages: [],
  popupMode: false,
}

VehicleShowcase.propTypes = {
  poster: string,
  interiorImage: string,
  assets360: arrayOf(string),
  sliderImages: arrayOf(string),
  popupMode: bool,
}

export default withInnerHeight(VehicleShowcase)
