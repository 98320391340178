import styled from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { InnerStyled } from '../../common/common'

const VehicleSectionStyled = styled.div`
  overflow: hidden;
  text-align: center;
  background: ${({ theme }) => rgba(theme.colors.blueGray, 0.7)};
  padding-top: ${({ theme }) => theme.gutter.gutter32};

  @media (${media.phone}) {
    padding-top: calc(${({ theme }) => theme.gutter.gutter56} * 2);
  }
`

const VehicleSectionInnerStyled = styled(InnerStyled)``

const VehicleSectionHeaderStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.gutter.gutter24};

  span {
    color: ${({ theme }) => theme.colors.cobalt};
  }

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter32};
  }
`

export {
  VehicleSectionStyled,
  VehicleSectionHeaderStyled,
  VehicleSectionInnerStyled,
}
