import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { decorHeading } from '../../common/Mixins'

const ContentBoxStyled = styled.div`
  text-align: center;

  @media (${media.phone}) {
    text-align: left;
  }

  @media (${media.tablet}) {
    max-width: 82%;
  }
`

const ContentBoxHeaderStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${decorHeading}

  span {
    color: ${({ theme }) => theme.colors.cobalt};
  }
`

const ContentBoxBodyStyled = styled.div`
  p + p,
  p + ul {
    margin-top: ${({ theme }) => theme.gutter.gutter16};
  }

  ul {
    list-style: unset;
    padding-left: ${({ theme }) => theme.gutter.gutter16};
    text-align: left;
  }
`

export { ContentBoxStyled, ContentBoxHeaderStyled, ContentBoxBodyStyled }
