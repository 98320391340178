import React, { useState, useCallback } from 'react'
import { Element } from 'react-scroll'
import PropTypes from 'prop-types'
import _first from 'lodash/first'

import PopupModal from '../PopupModal'
import BenefitsList from '../../components/BenefitsList'
import HeadingBox from '../../components/HeadingBox'
import VehicleShowcase from '../../components/VehicleShowcase'
import ColsSection from '../ColsSection'
import Logo from '../../components/Logo'
import { typesLogo } from '../../components/Logo/styles'
import MENU from '../../constants/menu'

const BenefitsWrapper = ({ benefitsVehicle, initialBenefitsPopupOpened }) => {
  const [isOpened, setIsOpened] = useState(initialBenefitsPopupOpened)

  const closeModal = useCallback(() => {
    setIsOpened(false)
  }, [setIsOpened])

  const openModal = useCallback(() => {
    setIsOpened(true)
  }, [setIsOpened])

  const {
    exteriorImages: assets360,
    assets: slidesImages,
    interiorImages,
    popupUrl,
  } = benefitsVehicle

  return (
    <>
      <Element id={`${MENU.BENEFITS.to}`} name={MENU.BENEFITS.to}>
        <ColsSection
          aside={(
            <HeadingBox
              label="Why AutoloadIT?"
              btnLabel="See it in action"
              onClick={openModal}
            >
              What used to take up to 4 people, 4 days to achieve
              mediocre results, can now be done by 1 person in as
              little as 5 minutes with stunning results which will
              power more sales for your business.
            </HeadingBox>
          )}
        >
          <BenefitsList />
        </ColsSection>
      </Element>
      <PopupModal
        closeTimeoutMS={370}
        isOpen={isOpened}
        closeModal={closeModal}
        logo={(
          <Logo
            href="/"
            aria-label="Go to home page"
            type={typesLogo.dark}
          />
        )}
        popupUrl={popupUrl}
      >
        <VehicleShowcase
          popupMode
          closeModal={closeModal}
          assets360={assets360}
          poster={_first(assets360)}
          interiorImage={interiorImages}
          sliderImages={slidesImages}
        />
      </PopupModal>
    </>
  )
}

BenefitsWrapper.defaultProps = {
  initialBenefitsPopupOpened: false,
}

BenefitsWrapper.propTypes = {
  initialBenefitsPopupOpened: PropTypes.bool,
  benefitsVehicle: PropTypes.shape({
    exteriorImages: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
    interiorImages: PropTypes.string,
    popupUrl: PropTypes.string,
  }).isRequired,
}

export default BenefitsWrapper
