import styled from 'styled-components'
import { rgba } from 'polished'

import { InnerStyled } from '../../common/common'
import media from '../../common/MediaQueries'

const PartnerSectionStyled = styled.div`
  overflow: hidden;
`

const PartnerSectionInnerStyled = styled(InnerStyled)`
  @media (${media.phone}) {
    padding-left: ${({ theme }) => theme.gutter.gutter96};
    padding-right: ${({ theme }) => theme.gutter.gutter64};
  }

  @media (${media.tablet}) {
    padding-left: ${({ theme }) => theme.gutter.gutter32};
    padding-right: ${({ theme }) => theme.gutter.gutter32};
  }
`

const PartnerSectionBodyStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: 'info' 'slider';
  grid-template-columns: 100%;

  @media (${media.phone}) {
    grid-template-areas: 'info slider';
    grid-template-columns: 70% 30%;
  }

  @media (${media.tablet}) {
    grid-template-columns: calc(50% - ${({ theme }) => theme.gutter.gutter32})
    calc(50% + ${({ theme }) => theme.gutter.gutter32});

    /* IE */
    display: -ms-grid;
    -ms-grid-columns: calc(50% - ${({ theme }) => theme.gutter.gutter32})
    calc(50% + ${({ theme }) => theme.gutter.gutter32});
  }
`

const PartnerSectionInfoStyled = styled.div`
  grid-area: info;
  position: relative;
  background: ${({ theme }) => theme.colors.indigo};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.gutter.gutter40} 0
  ${({ theme }) => theme.gutter.gutter96} 0;
  text-align: center;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    background: inherit;
    right: -${({ theme }) => theme.gutter.gutter16};
  }

  @media (${media.phone}) {
    text-align: left;
    padding: ${({ theme }) => theme.gutter.gutter150} 0;

    &::before {
      z-index: 2;
      width: 50vw;
      right: 100%;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50vw;
      height: 100%;
      background: inherit;
      z-index: 2;
      right: ${({ theme }) => theme.sizes.partnerSectionIndent};
    }
  }

  @media (${media.tablet}) {
    padding: ${({ theme }) => theme.gutter.gutter120} 0;

    /* IE */
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
`

const PartnerSectionInfoInnerStyled = styled.div`
  position: relative;
  z-index: 3;

  @media (${media.phone}) {
    max-width: ${({ theme }) => theme.sizes.partnerSectionInfoWidth};
    padding-bottom: calc(
    ${({ theme }) => theme.sizes.vehicleTeasersSliderNavSize}
    + ${({ theme }) => theme.gutter.gutter20}
    );
  }
`

const PartnerSectionHeaderStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.gutter.gutter20};

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter48};
  }
`

const PartnerSectionLabelStyled = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => rgba(theme.colors.blueGray, 0.75)};
  letter-spacing: ${({ theme }) => theme.letterSpacing.spacing20};
  margin-bottom: ${({ theme }) => theme.gutter.gutter16};

  @media (${media.phone}) {
    margin-bottom: ${({ theme }) => theme.gutter.gutter20};
  }
`

const PartnerSectionLogoStyled = styled.img`
  height: ${({ theme }) => theme.sizes.partnerSectionLogoHeightPhone};

  @media (${media.phone}) {
    height: ${({ theme }) => theme.sizes.partnerSectionLogoHeight};
  }

`

const PartnerSectionDescStyled = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

const PartnerSectionSliderStyled = styled.div`
  grid-area: slider;

  @media (${media.phone}) {
    margin: ${({ theme }) => theme.gutter.gutter150}
    0
    ${({ theme }) => theme.gutter.gutter150}
    -${({ theme }) => theme.sizes.partnerSectionIndent};
  }

  @media (${media.tablet}) {
    margin-top: ${({ theme }) => theme.gutter.gutter120};
    margin-bottom: ${({ theme }) => theme.gutter.gutter120};

    /* IE */
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
`

export {
  PartnerSectionStyled,
  PartnerSectionInnerStyled,
  PartnerSectionBodyStyled,
  PartnerSectionInfoStyled,
  PartnerSectionHeaderStyled,
  PartnerSectionLabelStyled,
  PartnerSectionLogoStyled,
  PartnerSectionDescStyled,
  PartnerSectionInfoInnerStyled,
  PartnerSectionSliderStyled,
}
